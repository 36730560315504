<template>
  <div>
    <div v-if="currentPolicy.length && isVisible">
      <draggable v-model:model-value="currentPolicy" item-key="id">
        <template v-slot:item="{ element }">
          <div class="d-flex no-block align-items-center justify-content-between mb-3 border rounded p-3 bg-white">
            <font-awesome-icon icon="grip-vertical" class="text-muted drag-icon" />

            <select
              v-for="(_, index) in element"
              :key="index"
              v-model="element[index]"
              class="form-control ms-2"
            >
              <option
                v-for="(tag, tagIndex) in tags"
                :value="tag"
                :key="tagIndex"
              >
                {{ tag }}
              </option>
            </select>

            <a @click.prevent="deletePolicyRow(element)" href="#" class="ms-2">
              <font-awesome-icon icon="xmark" class="text-danger" />
            </a>
          </div>
        </template>
      </draggable>

      <div class="mt-3 mb-5" aria-label="Policy management">
        <button @click="addPolicyRow()" class="btn btn-outline-primary me-2">
          Добавить политику
        </button>
        <button @click="savePriority()" class="btn btn-primary">
          Сохранить приоритеты
        </button>
      </div>
    </div>
    <div v-else>
      <b-spinner></b-spinner>
    </div>

  </div>
</template>

<script>
import { makeToast } from '@/custom_functions/custom_functions';

export default {
  name: 'PolicyPriority',

  props: {
    tags: {
      type: Array,
      required: true,
    },

    policy: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentPolicy: [[null, null, null, null]],
      isVisible: true,
    };
  },

  created() {
    this.currentPolicy = this.policy;
  },

  methods: {
    updateDraggable() {
      this.isVisible = false;
      this.$nextTick(() => {
        this.isVisible = true;
      });
    },

    addPolicyRow() {
      this.currentPolicy.push([null, null, null, null]);
      this.updateDraggable();
    },

    deletePolicyRow(item) {
      this.currentPolicy = this.currentPolicy.filter((row) => {
        if (JSON.stringify(row) !== JSON.stringify(item)) return row;
      });
      this.updateDraggable();
    },

    savePriority() {
      this.$store.dispatch('tags/changeTagsSettings', { tags: this.tags, policy: this.currentPolicy });
      makeToast('Приоритеты сохранены', 'success');
    },
  },
}
</script>
