import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useRouteTabs(tabs = [], mainTabValue = '') {
  if (
    tabs.some((tab) => !tab.value)
    || tabs.every((tab) => tab.value !== mainTabValue)
  ) throw Error('Incorrect params of useRouteTabs()');

  const route = useRoute();
  const router = useRouter();

  const tabValue = computed({
    get: () => {
      const index = tabs.findIndex((tab) => tab.value === route.query.tab);
      return index >= 0 ? index : 0;
    },
    set: (value) => {
      router.replace({
        query: { tab: value < tabs.length ? tabs[value].value : mainTabValue },
      });
    }
  });

  return { tabValue };
}
