<template>
  <div v-if="unit && course" class="container">
    <h1 class="h5 text-primary d-flex mb-4">
      <router-link :to="`/courses/${course.id}/edit?tab=content`" class="hidden-text" id="course-name">{{ course.name }}</router-link>
      <b-tooltip target="course-name">{{ course.name }}</b-tooltip>
      <span class="mx-2">/</span>
      <span class="nowrap">{{ unit.name }}</span>
    </h1>

    <b-tabs content-class="mt-3">
      <b-tab title="Основное" >
        <UnitMainSettings />
      </b-tab>

      <b-tab title="Стенды">
        <UnitStands />
      </b-tab>

      <b-tab title="Уроки">
        <UnitLessonsEdit />
      </b-tab>
    </b-tabs>
  </div>

  <div v-else class="loader text-center">
    <b-spinner />
  </div>
</template>

<script>
import UnitLessonsEdit from '@/components/units/UnitLessonsEdit.vue';
import UnitStands from '@/components/units/UnitStands.vue';
import UnitMainSettings from '@/components/units/UnitMainSettings.vue';


export default {
  name: 'UnitEdit',

  components: { UnitLessonsEdit, UnitStands, UnitMainSettings },

  computed: {
    course() {
      return this.$store.getters['course/getCourse'];
    },

    unit() {
      const unit = this.$store.getters['unit/getUnit'];
      if (unit && Number(unit.id) === Number(this.$route.params.id)) return unit;
      else return null;
    },
  },

  created() {
    this.$store.dispatch('course/fetchCourse', this.$route.query.course);
    this.$store.dispatch('unit/fetchUnit', this.$route.params.id);
  },

  mounted() {
    this.$root.$on('bv::tooltip::show', (bvEvent) => {
      const elName = bvEvent.target;
      if (elName) {
        if (
          !(elName.scrollWidth > elName.clientWidth)
          && elName.classList.contains('hidden-text')
        ) {
          bvEvent.preventDefault();
        }
      }
    });
  },
};
</script>

<style scoped>
.hidden-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.nowrap {
  white-space: nowrap;
}
</style>