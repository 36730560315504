<template>
  <div v-if="scripts" class="px-3">
    <div class="mb-4 d-flex justify-content-between align-items-center">
      <h2>Скрипты</h2>
      <form class="input-group w-50" @submit.prevent="findScripts">
        <input
          placeholder="Скрипт или фрагмент скрипта"
          type="search"
          class="form-control"
          v-model="query"
        />
        <button class="btn btn-primary" type="submit">Поиск</button>
      </form>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Скрипт</th>
            <th scope="col">Пользователь</th>
            <th scope="col">Старт</th>
            <th scope="col">Конец</th>
            <th scope="col">Создано</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="script in scripts" :key="script.id">
            <td scope="row">
              <router-link
                :to="{name: 'script_runners_show', params: {id: script.id}}"
                :id="'more-button-'+script.id"
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon icon="info-circle" size="lg"/>
              </router-link>
              <b-tooltip :target="'more-button-'+script.id">Подробнее</b-tooltip>
            </td>
            <td class="w-50">{{script.script}}</td>
            <td class="text-center">
              <template v-if="script && script.runnable && script.runnable.cmd_args">
                {{ script.runnable.cmd_args.$login }}
              </template>
            </td>
            <td>{{ formatDateTime(script.started_at) }}</td>
            <td>{{ formatDateTime(script.stopped_at) }}</td>
            <td>{{ formatDateTime(script.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-if="totalPages > 1"
      v-model="$route.params.page"
      :total-rows="scripts.length * totalPages"
      :per-page="scripts.length"
      @change="goToPage($event)"
      class="position-sticky"
      style="bottom: 10px;"
    ></b-pagination>
  </div>
  <div v-else class="loader text-center">
    <b-spinner></b-spinner>
  </div>
</template>
<script>
import { formatDateTime } from "@/custom_functions/custom_functions.js";

export default {
  name: "ScriptRunnersIndex",

  data() {
    return {
      scripts: null,
      query: null,
      currentQuery: null,
      totalPages: null,
    };
  },

  created() {
    this.init();
  },

  methods: {
    formatDateTime,

    init() {
      this.getScripts();
    },

    async findScripts() {
      this.currentQuery = this.query;
      if (this.$route.params.page != 1)
        await this.$router.replace({ params: { page: 1 } });
      this.getScripts();
    },

    async getScripts() {
      let params = {};

      if (this.currentQuery) params["script"] = this.currentQuery;
      if (this.$route.params.page > 1) params["page"] = this.$route.params.page;

      let response = await this.$apiClient.get("/v1/script_runners", {
        params: params,
      });

      this.scripts = response.data.script_runners;
      this.totalPages = response.data.meta.total_pages;
    },

    async goToPage(page) {
      if (this.$route.params.page != page) {
        await this.$router.replace({ params: { page: page } });
        this.getScripts();
      }
    },
  },
};
</script>