<template>
  <div class="mb-4">
    <b-button
      variant="link"
      class="text-dark no-outline p-0 mb-3"
      @click="visible = !visible"
      aria-controls="collapse-courses"
    >
      <span class="h3 me-2">На какие курсы действует</span>
      <font-awesome-icon
        class="arrow"
        :class="visible ? null : 'collapsed'"
        icon="angle-down"
        size="lg"
      />
    </b-button>

    <b-collapse
      id="collapse-courses"
      class="mt-2"
      v-model="visible"
    >
      <div class="row">
        <div class="col-4">
          <div class="mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="selectAllCourses"
              >
              <label class="form-check-label">
                Все курсы
              </label>
            </div>
          </div>
        </div>

        <div
          class="col-4"
          v-for="(course, index) in courses"
          :key="index"
        >
          <div class="mb-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="selectedCoursesLocal"
                :value="course.id"
              >
              <label class="form-check-label">
                {{ course.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'CampaignCourses',

  props: {
    courses: Array,
    selectedCourses: Array,
  },

  data() {
    return {
      selectedCoursesLocal: this.selectedCourses || [],
      visible: true,
    }
  },

  watch: {
    selectedCoursesLocal: {
      handler() {
        this.$emit("updateCourses", this.selectedCoursesLocal);
      },
      deep: true,
    }
  },

  computed: {
    selectAllCourses: {
      get: function () {
        return this.courses ? this.selectedCoursesLocal.length === this.courses.length : false;
      },
      set: function (value) {
        let selectedCoursesLocal = [];

        if (value) {
          this.courses.forEach(function (course) {
            selectedCoursesLocal.push(course.id);
          });
        }

        this.selectedCoursesLocal = selectedCoursesLocal;
      }
    }
  },
}
</script>

<style scoped>
.no-outline:focus {
  box-shadow: none;
}

.arrow {
  transition: all 0.3s;
}

.collapsed {
  transform: rotate(180deg);
}
</style>