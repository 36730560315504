<template>
  <div class="container-fluid">
    <div class="mb-4 d-flex justify-content-between align-items-baseline">
      <h2>Выгрузки</h2>
      <router-link
        v-if="canEdit"
        to="/reports/new"
        class="btn btn-primary"
      >
        Создать выгрузку
      </router-link>
    </div>

    <table class="table table-hover table-responsive-lg table-sm">
      <thead>
        <tr>
          <th>Имя файла</th>
          <th>Периодичность</th>
          <th>SQL запрос</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(report, index) in reports"
          :key="report.id"
        >
          <td>{{ report.name }}</td>
          <td>{{ formatCron(report.cron) }}</td>
          <td>
            <pre>{{ report.sql }}</pre>
          </td>
          <td>
            <div class="d-flex justify-content-end">
              <router-link
                v-if="canEdit"
                :to="`/reports/${report.id}`"
                class="px-4"
              >
                <font-awesome-icon
                  icon="pen"
                  class="text-dark small"
                />
              </router-link>
              <a
                href="#"
                class="px-4"
                @click.prevent="downloadReport(index)"
              >
                Скачать
              </a>
              <a
                v-if="canEdit"
                href="#"
                class="px-4"
                @click.prevent="$bvModal.show(`modal-delete-${report.id}`)"
              >
                <font-awesome-icon
                  icon="trash"
                  class="text-danger small"
                />
              </a>
              <b-modal
                :id="`modal-delete-${report.id}`"
                hide-footer
                title="Подтвердите действие"
              >
                <div class="d-block text-center">
                  <h4>Выгрузка в {{ report.name }} будет приостанвлена</h4>
                </div>
                <button
                  class="btn btn-danger btn-block mt-3 w-100"
                  @click="deleteReport(index)"
                >
                  Удалить
                </button>
                <button
                  class="btn btn-secondary btn-bloсk mt-3 w-100"
                  @click="$bvModal.hide(`modal-delete-${report.id}`)"
                >
                  Отмена
                </button>
              </b-modal>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { apiClient } from '../../custom_functions/api_client';
import { makeToast } from '../../custom_functions/custom_functions';
import { toString } from 'cronstrue/i18n';

export default {
  name: 'ReportsIndex',

  computed: {
    ...mapGetters('reports', { reports: 'getReports' }),

    canEdit() {
      return this.$store.getters['profile/isAdmin'];
    },
  },

  created() {
    this.$store.dispatch('reports/fetchReports');
    this.$store.dispatch('profile/fetchCurrentUser');
  },

  methods: {
    formatCron(value) {
      return toString(value, { locale: 'ru' });
    },

    async deleteReport(index) {
      const { id } = this.reports[index];
      await apiClient.delete(`/v2/google_reports/${id}`);
      this.reports.splice(index, 1);
      this.$bvModal.hide(`modal-delete-${id}`);
      makeToast('Выгрузка была остановлена, конфигурация удалена', 'success');
    },

    async downloadReport(index) {
      const { id, name } = this.reports[index];
      const response = await this.$apiClient.get(
        `/v2/google_reports/${id}/report.csv`,
        { responseType: 'arraybuffer' },
      );
      alert('Отчет скачивается, подождите');
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.download = `${name}.csv`;
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
      link.remove();
    },
  },
};
</script>
