<template>
  <div class="container">
    <h2 class="mb-5">Создать уведомление</h2>
    <form v-if="users && courses">
      <div class="row">
        <div class="mb-3 col-4">
          <label for="notification_kind" class="mb-2">Вид уведомления</label>
          <select class="form-control" id="notification_kind" v-model="notification.delivery.notification_kind">
            <option v-for="(kind, index) in kinds" :value="kind" :key="index">{{ formatKind(kind) }}</option>
          </select>
        </div>
        <div class="mb-3 col-4">
          <label class="mb-2" for="email_delivery_method">Способ доставки email</label>
          <select class="form-control" id="email_delivery_method" v-model="notification.delivery.email_delivery_method"
            :disabled="notification.delivery.only_lk">
            <option value="smtp">PostmarkApp</option>
            <option value="sendpulse">SendPulse</option>
          </select>
        </div>
        <div class="col-4">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="only_lk" value="courses"
              v-model="notification.delivery.only_lk" @change="notification.delivery.email_delivery_method = null">
            <label class="form-check-label" for="only_lk">
              Только в ЛК
            </label>
          </div>
        </div>
      </div>
      <hr class="my-4">
      <h3 class="h5 fw-bold mb-4">Кому отправить</h3>
      <div class="row">
        <div class="col-4">
          <div class="form-check mb-2">
            <input class="form-check-input" type="radio" id="recipients_type_courses" value="courses"
              v-model="recipientsType">
            <label class="form-check-label" for="recipients_type_courses">
              Студентам выбранных курсов
            </label>
          </div>
          <CustomSelect v-if="courses" v-model:model-value="notification.delivery.course_ids"
            :disabled="recipientsType !== 'courses'" :options="courseIdsOptions" :errors="errors.delivery.course_ids"
            id="delivery-course-ids" class="mb-3" placeholder="Выберите курсы" multiple required search />
          <div class="form-check" v-show="notification.delivery.course_ids.length">
            <input class="form-check-input" type="checkbox" id="only_to_not_signed_in_courses"
              v-model="notification.delivery.only_to_not_signed_in" @change="fetchRecepientsCount">
            <label class="form-check-label" for="only_to_not_signed_in_courses">
              Только тем из них, кто не вошел в ЛК
            </label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check mb-2">
            <input class="form-check-input" type="radio" id="recipients_type_students" value="students"
              v-model="recipientsType">
            <label class="form-check-label" for="recipients_type_students">
              Определённому студенту
            </label>
          </div>
          <CustomSelect v-if="users" v-model:model-value="userId" :disabled="recipientsType !== 'students'"
            :options="userIdsOptions" :errors="errors.delivery.user_ids" id="delivery-user-ids"
            placeholder="Введите имя, email или логин студента" class="mb-3" required
            :search="(query) => this.$store.dispatch('users/fetchUsersByQuery', query)" />
          <div class="form-check" v-show="notification.delivery.user_ids.length">
            <input class="form-check-input" type="checkbox" id="only_to_not_signed_in_students"
              v-model="notification.delivery.only_to_not_signed_in" @change="fetchRecepientsCount">
            <label class="form-check-label" for="only_to_not_signed_in_students">
              Только тем из них, кто не вошел в ЛК
            </label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-check mb-1">
            <input class="form-check-input" type="radio" id="recipients_type_all" value="all" v-model="recipientsType">
            <label class="form-check-label" for="recipients_type_all">
              Всем студентам «Слёрма»
            </label>
          </div>
          <span v-if="usersNumber" class="h2 mb-3 d-block"
            :class="notification.delivery.send_to_all ? 'text-dark' : 'text-muted'">
            {{ formatUsersNumber(usersNumber) }}
          </span>
          <div class="form-check" v-show="notification.delivery.send_to_all">
            <input class="form-check-input" type="checkbox" id="only_to_not_signed_in_all"
              v-model="notification.delivery.only_to_not_signed_in" @change="fetchRecepientsCount">
            <label class="form-check-label" for="only_to_not_signed_in_all">
              Только тем из них, кто не вошел в ЛК
            </label>
          </div>
        </div>
      </div>
      <hr class="my-4">
      <div class="row">
        <div class="col-8">
          <div class="mb-3">
            <label for="title" class="mb-2">Заголовок</label>
            <input type="text" class="form-control" id="title" v-model="notification.content.title">
          </div>
          <div class="mb-3">
            <label for="text" class="mb-2">Текст</label>
            <Editor v-model:model-value="notification.content.text" id="notification-content-text"
              placeholder="Текст записи" />
          </div>
        </div>
        <div v-show="checkRecipients" class="col-4">
          <div class="card h-100 bg-light">
            <div class="card-body">
              <h3 class="h5 fw-bold">Кто получит уведомления</h3>
              <p>{{ recipientsText }}</p>
            </div>
          </div>
        </div>
      </div>
      <button :class="{
        'btn-secondary': isSending,
      }" :disabled="isSending" type="button" @click="sendNotification" class="btn btn-primary">
        Отправить уведомление
      </button>
    </form>
    <div v-else class="loader text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect.vue';
import { apiClient } from '@/custom_functions/api_client';
import { mapGetters } from 'vuex';
import { makeToast } from '../../custom_functions/custom_functions';

export default {
  name: 'NotificationsNew',

  components: {
    CustomSelect,
  },

  data() {
    return {
      userId: null,
      notification: {
        delivery: { course_ids: [], user_ids: [], only_to_not_signed_in: false },
        content: { text: '' },
      },
      errors: {
        delivery: {
          course_ids: [],
          user_ids: [],
        },
      },
      recipientsType: null,
      isSending: false,
    };
  },

  computed: {
    ...mapGetters('notifications', { usersNumber: 'getUsersNumber', kinds: 'getKinds' }),
    ...mapGetters('courses', { courses: 'getCoursesOmitPagination' }),

    recipientsCount() {
      return this.$store.getters['notifications/getRecipientsCount'];
    },

    users() {
      return this.$store.getters['users/getUsers'];
    },

    recipientsText() {
      let text = '';
      if (this.recipientsCount) {
        if (this.recipientsType === 'courses') {
          text += 'Студенты курса(-ов) ';
          const courseNames = this.notification.delivery.course_ids.map((id) => this.courses.find((course) => course.id === id).name);
          text += courseNames.join(', ');
        }
        else if (this.recipientsType === 'students') text += 'Выбранные студенты';
        else if (this.recipientsType === 'all') text += 'Все студенты в системе';
        if (this.notification.delivery.only_to_not_signed_in) text += ', которые не вошли в личный кабинет';
        text += ` — ${this.recipientsCount}`;
      };
      return text;
    },

    checkRecipients() {
      return (
        !!this.notification.delivery.user_ids.length
        || !!this.notification.delivery.course_ids.length
        || !!this.notification.delivery.send_to_all
      )
    },

    courseIdsOptions() {
      const courses = this.courses;
      return courses ? courses.map((course) => ({ name: course.name, value: course.id })) : null;
    },

    userIdsOptions() {
      if (!this.users) return [];

      const users = this.users;

      const options = users.map((user) => {
        if (!user.full_name) {
          const userWithoutName = { name: `${user.email} - ${user.login}`, value: user.id };

          return userWithoutName;
        }

        const userWithName = { name: `${user.full_name} - ${user.email} - ${user.login}`, value: user.id };

        return userWithName;
      })

      return options;
    },
  },

  watch: {
    recipientsType(value) {
      this.notification.delivery.course_ids = [];
      this.userId = null;
      this.notification.delivery.only_to_not_signed_in = false;
      this.notification.delivery.send_to_all = (value === 'all');
    },

    'notification.delivery': {
      handler() {
        this.fetchRecepientsCount();
      },
      deep: true,
    },

    userId(value) {
      this.notification.delivery.user_ids = value ? [value] : [];
    },
  },

  created() {
    this.$store.dispatch('notifications/fetchMeta');
    this.$store.dispatch('courses/fetchCoursesOmitPagination');
    this.$store.dispatch('users/fetchUsers');
  },

  methods: {
    formatKind(value) {
      let result;
      if (value === 'announcement') result = 'Анонсы и дайджесты';
      else if (value === 'from_organizer') result = 'Сообщения от организаторов';
      else result = value;
      return result;
    },

    resetData() {
      this.notification = {
        delivery: {
          send_to_all: false,
          notification_kind: null,
          only_to_not_signed_in: false,
          email_delivery_method: null,
          course_ids: [],
          user_ids: [],
        },
        content: {
          title: '',
          text: '',
        },
      };
      this.recipientsType = null;
    },

    async sendNotification() {
      if (!this.notification.delivery.notification_kind) {
        makeToast("Пожалуйста, выберите вид уведомления", "danger");
        return
      }
      if (!this.notification.delivery.email_delivery_method && !this.notification.delivery.only_lk) {
        makeToast("Пожалуйста, выберите способ доставки email", "danger");
        return
      }
      if (!this.checkRecipients) {
        makeToast("Пожалуйста, выберите, кому отправить уведомление", "danger");
        return
      }
      if (
        !this.notification.content.title
        || !this.notification.content.text
      ) {
        makeToast("Пожалуйста, введите заголовок и текст уведомления", "danger");
        return
      }
      this.isSending = true;
      await apiClient.post('/v2/mass_deliveries', { notification: this.notification });
      await new Promise((res) => setTimeout(res, 2000));
      makeToast("Уведомление успешно отправлено!", "success");
      this.isSending = false;
      this.resetData();
    },

    formatUsersNumber(value) {
      if (value % 10 > 1 && value % 10 <= 4 && Math.floor((value % 100) / 10) !== 1) return `${value} человека`;
      return `${value} человек`;
    },

    getUserLabel(user) {
      if (user.full_name) return user.full_name.trim() || user.email;
      return user.email;
    },

    fetchRecepientsCount() {
      this.$store.dispatch(
        'notifications/fetchMeta',
        {
          coursesIds: this.notification.delivery.course_ids,
          usersIds: this.notification.delivery.user_ids,
          onlyToNotSignedIn: this.notification.delivery.only_to_not_signed_in,
        },
      );
    },
  },
};
</script>
