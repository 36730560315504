<template>
  <div>
    <span v-for="(item, index) in items" :key="index" class="text-primary">
      <router-link v-if="index < items.length - 1" :to="item.link" class="me-2">{{ item.title }}</router-link>
      <span v-else>{{ item.title }}</span>
      <span v-if="index < items.length - 1" class="me-2">-</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',

  props: { items: { type: Array, required: true } },
}
</script>

<style>

</style>