import { apiClient } from '../../custom_functions/api_client';

export default {
  namespaced: true,

  state: {
    reports: null,
    report: null,
  },

  getters: {
    getReports(state) {
      return state.reports;
    },

    getReport(state) {
      return state.report;
    },
  },

  actions: {
    async fetchReports({ commit }) {
      const response = await apiClient.get('/v2/google_reports');
      commit('updateReports', response.data.google_reports);
    },

    async fetchReport({ commit }, id) {
      const response = await apiClient.get(`/v2/google_reports/${id}`);
      commit('updateReport', response.data.google_report);
    },
  },

  mutations: {
    updateReports(state, reports) {
      state.reports = reports;
    },

    updateReport(state, report) {
      state.report = report;
    },
  }
}