import { apiClient } from '@/custom_functions/api_client';

export default {
  namespaced: true,

  state: { reviewers: null, reviewer: null, step: null, answer: null },

  getters: {
    getReviewers: (state) => state.reviewers,
    getReviewer: (state) => state.reviewer,
    getStep: (state) => state.step,
    getAnswer: (state) => state.answer,
  },

  actions: {
    async fetchReviewers({ commit }) {
      const response = await apiClient.get('/v2/reviewers');
      commit('updateReviewers', response.data.reviewers);
    },

    async fetchReviewer({ commit }, id) {
      const response = await apiClient.get(`/v2/reviewers/${id}`);
      commit('updateReviewer', response.data.reviewer);
    },

    async fetchStep({ commit }, payload) {
      const response = await apiClient.get(`/v2/reviewers/${payload.reviewerId}/steps/${payload.stepId}`);
      commit('updateStep', response.data.step);
    },

    async fetchAnswer({ commit }, payload) {
      const response = await apiClient.get(`/v2/reviewers/${payload.reviewerId}/attempts/${payload.answerId}`);
      commit('updateAnswer', response.data.attempt);
    },
  },

  mutations: {
    updateReviewers(state, reviewers) {
      state.reviewers = reviewers;
    },

    updateReviewer(state, reviewer) {
      state.reviewer = reviewer;
    },

    updateStep(state, step) {
      state.step = step;
    },

    updateAnswer(state, answer) {
      state.answer = answer;
    },
  },
}