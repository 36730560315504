<template>
  <div v-if="session" class="px-3">
    <h2 class="mb-4">Стенд</h2>
    <div class="d-flex mb-2" v-if="showStopSessionButtons">
      <button class="btn btn-danger btn-sm me-2" @click="stopSession">Остановить площадку</button>
      <!-- <button class="btn btn-danger btn-sm" @click="closeSession">Закрыть сессию</button> -->
    </div>
    <div class="d-flex align-items-end mb-2">
      <span class="me-2 fw-bold">Пользователь:</span>
      <span>{{ session.student_email }}</span>
    </div>
    <div class="d-flex align-items-end mb-2">
      <span class="me-2 fw-bold">Логин:</span>
      <span>{{ session.login }}</span>
    </div>
    <div class="d-flex align-items-end mb-2">
      <span class="me-2 fw-bold">Курс:</span>
      <span>{{ session.course_name }}</span>
    </div>
    <div class="d-flex align-items-end mb-2">
      <span class="me-2 fw-bold">Урок:</span>
      <router-link :to="`/units/${session.unit_id}/edit`">{{ session.unit_name }}</router-link>
    </div>
    <div class="d-flex align-items-end mb-2">
      <span class="me-2 fw-bold">Статус:</span>
      <span>{{ formatSessionState(session.state) }}</span>
    </div>
    <div class="d-flex align-items-end mb-2">
      <span class="me-2 fw-bold">Дата начала:</span>
      <span>{{ formatDateTime(session.date_from) }}</span>
    </div>
    <div class="d-flex align-items-center mb-2">
      <span class="me-2 fw-bold">Дата конца:</span>
      <span class="me-4">{{ formatDateTime(session.date_to) }}</span>
      <button class="btn btn-primary btn-sm" @click="$bvModal.show('new_date_to')">Изменить</button>
      <b-modal id="new_date_to" hide-footer>
        <template v-slot:modal-title>Введите время окончания работы стенда</template>
        <div class="mb-3">
          <label for="date">Выберите дату:</label>
          <b-form-datepicker id="date" class="form-control" v-model="newDateTo.date" locale="ru"></b-form-datepicker>
        </div>
        <div class="mb-3">
          <label for="time">Выберите время:</label>
          <b-form-timepicker id="time" class="form-control" v-model="newDateTo.time" locale="ru"></b-form-timepicker>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary mx-1" @click.prevent="setNewDateTo">Сохранить</button>
          <button class="btn btn-secondary mx-1" @click="$bvModal.hide('new_date_to')">Отменить</button>
        </div>
      </b-modal>
    </div>
  </div>
  <div v-else class="loader text-center">
    <b-spinner></b-spinner>
  </div>
</template>

<script>
import { formatDateTime, formatSessionState, makeToast } from '@/custom_functions/custom_functions.js';
import moment from 'moment';

export default {
  name: 'StandsSessionsShow',

  data() {
    return {
      newDateTo: {
        date: null,
        time: null,
      },
    };
  },

  computed: {
    showStopSessionButtons() {
      return this.session.hangs === true || this.session.state === 'running';
    },
    session() {
      return this.$store.getters['standsSessions/getCurrentSession'];
    },
  },

  created() {
    this.init();
  },

  methods: {
    formatDateTime,
    formatSessionState,

    async init() {
      await this.$store.dispatch('standsSessions/fetchCurrentSession', this.$route.params.id);
      if (this.session) this.setDateToForm(this.session.date_to);
    },

    setDateToForm(date) {
      const m = moment(date);
      this.newDateTo.date = m.utcOffset('GMT+03:00').format('YYYY-MM-DD');
      this.newDateTo.time = m.utcOffset('GMT+03:00').format('HH:mm:ss');
    },

    async setNewDateTo() {
      const newDate = moment(`${this.newDateTo.date}T${this.newDateTo.time}`).utcOffset('GMT+03:00');
      const postData = {
        stand_session: {
          "date_to(3i)": newDate.date() + '',
          "date_to(2i)": newDate.month() + 1 + '',
          "date_to(1i)": newDate.year() + '',
          "date_to(4i)": (newDate.hour() > 9 ? '' : '0') + newDate.hour(),
          "date_to(5i)": (newDate.minute() > 9 ? '' : '0') + newDate.minute(),
        },
      };
      const response = await this.$apiClient.patch(`/v1/stand_sessions/${this.session.id}`, postData);
      makeToast('Изменения сохранены', 'primary');
      this.$store.commit('standsSessions/updateCurrentSession', response.data.stand_session);
      this.$bvModal.hide('new_date_to');
    },

    stopSession() {
      this.$apiClient.delete('/v1/stand_sessions', { params: { unit_id: this.session.unit_id, participation_id: this.session.participation_id } })
        .then(() => {
          makeToast('Площадка останавливается', 'success');
        })
        .catch(() => {
          makeToast('Не удалось остановить площадку, попробуйте снова или закройте сессию', 'danger');
        });
    },

    closeSession() {
      this.$apiClient.post(`/v1/stand_sessions/${this.$route.params.id}/close`)
        .then(() => {
          makeToast('Сессия закрывается', 'success');
        })
        .catch(() => {
          makeToast('Не удалось закрыть сессию', 'danger');
        });
    },
  },
};
</script>
