import moment from "moment";

export default function validateUnit(unit) {
  let result = {};
  
  if (unit.deadline && unit.deadline.hasSubmissionsDeadline) {
    if (!unit.deadline.dateFrom || !unit.deadline.dateTo) {
      if (!unit.deadline.dateFrom) {
        result.deadlineDateFrom = 'Начало дедлайна не может быть пустым';
      }
      if (!unit.deadline.dateTo) {
        result.deadlineDateTo = 'Окончание дедлайна не может быть пустым';
      }
    } else if (
      unit.deadline.dateFrom === unit.deadline.dateTo
      && !unit.deadline.timeFrom
      && !unit.deadline.timeTo
    ) {
      result.deadlineDateTo = 'Окончание дедлайна не может быть раньше начала дедлайна';
    } else if (unit.deadline.timeFrom && !unit.deadline.timeTo) {
      result.deadlineTimeTo = 'Время окончание дедлайна не может быть пустым';
    } else if (!unit.deadline.timeFrom && unit.deadline.timeTo) {
      result.deadlineTimeFrom = 'Время начало дедлайна не может быть пустым';
    } else if (
      unit.deadline.dateTo
      && unit.deadline.dateFrom > unit.deadline.dateTo
    ) {
      result.deadlineDateTo = 'Окончание дедлайна не может быть раньше начала дедлайна';
    } else if (
      unit.deadline.dateFrom === unit.deadline.dateTo
      && unit.deadline.timeTo
      && unit.deadline.timeFrom >= unit.deadline.timeTo
    ) {
      result.deadlineTimeTo = 'Окончание дедлайна не может быть раньше начала дедлайна';
    }
  }
  
  if (unit.autoPublication && unit.autoPublication.hasSubmissionsAutoPublication) {
    if (!unit.autoPublication.dateFrom) result.autoPublicationDateFrom = "Дата публикации не может быть пустой"
    else if (!unit.autoPublication.timeFrom) result.autoPublicationTimeFrom = "Время публикации не может быть пустым"
    else if (
      moment(`${unit.autoPublication.dateFrom}T${unit.autoPublication.timeFrom}+03:00`)
      <= moment(new Date()).utcOffset('GMT+03:00')
    ) {
      result.autoPublicationTimeFrom = "Дата и время публикации не может быть раньше текущей";
    }
  }

  return result;
}