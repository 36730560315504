<template>
  <form>
    <div class="row">
      <div class="mb-3 col-6">
        <label for="name" class="mb-2">Название</label>
        <input v-model="webinar.name" id="name" placeholder="Название вебинара" type="text" class="form-control">
      </div>

      <div class="mb-3 col-3">
        <label for="publication_status" class="mb-2">Статус публикации</label>
        <select v-model="webinar.publication_status" id="publication_status" class="form-control">
          <option value="published">Опубликовано</option>
          <option value="draft">Черновик</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <DateTimePicker
          v-model:model-value="webinar.start_time"
          date-label="Дата проведения"
          time-label="Время начала (мск)"
          date-placeholder="Например, 1 января"
          time-placeholder="00:00"
        />
        <DateTimePicker
          v-model:model-value="webinar.end_time"
          date-label="Дата окончания"
          time-label="Время окончания (мск)"
          date-placeholder="Например, 1 января"
          time-placeholder="00:00"
        />
      </div>

      <div class="mb-3 col-6">
        <div class="row">
          <div class="mb-3 col-6">
            <label for="webinar-type" class="mb-2">Тип трансляции</label>
            <select id="webinar-type" v-model="webinar.type" class="form-control">
              <option value="YoutubeWebinar">YouTube</option>
              <option value="PlainWebinar">Другая платформа</option>
            </select>
          </div>
          <div class="col-6" v-if="webinar.type === 'PlainWebinar'">
            <label for="webinar-img" class="mb-2">Баннер вебинара</label>
            <input type="file" id="webinar-img" accept="image/*" @change="webinar.image = $event.target.files[0]" />
          </div>
        </div>
        <div>
          <label for="webinar-url" class="mb-2">{{ webinar.type === 'YoutubeWebinar' ? 'Ссылка на YouTube-трансляцию' : 'Ссылка на трансляцию' }}</label>
          <input
            v-model="webinar.data.url"
            id="webinar-url"
            type="text"
            class="form-control"
            :placeholder="webinar.type === 'YoutubeWebinar' && 'https://www.youtube.com/watch?v=...'"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="mb-3 col-6">
        <label for="webinar-description" class="mb-2">Программа вебинара</label>
        <div>
          <Editor
            v-model:model-value="webinar.description"
            id="webinar-description"
            placeholder="Программа вебинара"
          />
        </div>
      </div>

      <div class="mb-3 col-6" v-if="speakers && speakers.length">
        <label for="speakers" class="mb-2">Спикеры</label>

        <v-select
          v-model:model-value="speakerIds"
          :reduce="speaker => speaker.id"
          :options="speakers"
          label="full_name"
          taggable
          multiple
          placeholder="Выберите спикера"
          id="speakers"
        >
          <template #option="{ full_name, photo }">
            <b-avatar :src="photo.url ? photo.url: ''" :variant="photo.url ? 'light' : 'primary'" size="20px" class="me-2" />
            <span>{{ full_name }}</span>
          </template>
        </v-select>
      </div>
    </div>
  </form>
</template>

<script>
import DateTimePicker from '@/components/DateTimePicker.vue';

export default {
  name: 'WebinarsForm',

  components: { DateTimePicker },

  props: { webinar: { required: true, type: Object } },

  data() {
    return { speakerIds: [] };
  },

  computed: {
    speakers() {
      return this.$store.getters['speakers/getSpeakers'];
    },
  },

  created() {
    this.speakerIds = this.webinar.speaker_ids;
  },

  watch: {
    speakerIds: {
      handler(value) {
        this.webinar.speaker_ids = value;
      },
      deep: true,
    },
  },
};
</script>
