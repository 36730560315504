<template>
  <div>
    <div class="mb-3">
      <input
        type="number"
        class="form-control w-25"
        :required="!withoutLimit"
        :disabled="withoutLimit"
        v-model.lazy="limit"
        placeholder="Количество попыток"
      />
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="without-limit" v-model='withoutLimit'>
      <label class="form-check-label" for="without-limit">
        Не ограничивать попытки
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepAttemptsLimit',

  data() {
    return {
      withoutLimit: false,
      limit: null
    };
  },

  props: [ 'value' ],

  created() {
    this.limit = this.value;
    this.withoutLimit = !this.value;
  },

  watch: {
    withoutLimit(value) {
      if (value) {
        this.limit = null;
      }
    },

    limit(value) {
      this.$emit('input', value);
    },

    value(value) {
      this.limit = value;
      this.withoutLimit = !value;
    },
  },
}
</script>
