<template>
  <div class="mb-3">
    <label v-if="label" :for="id" class="mb-2">{{ label }}{{ required ? '*' : '' }}</label>

    <div class="input-group">
      <slot _class="input-group-text" name="prepend"></slot>
      <input
        :id="id"
        :class="{ 'form-control-sm': size === 'sm', 'is-invalid': errors.length }"
        :disabled="disabled"
        @paste.prevent
        @input="formatNumber"
        :value="modelValue"
        ref="number-input"
        class="form-control"
        type="number"
      />
      <span v-for="(error, index) in errors" :key="index" class="invalid-feedback">{{ error }}</span>
    </div >
    <small class="form-text text-muted" v-if="tip">{{ tip }}</small>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',

  props: {
    label: { type: String, default: '' },
    id: { type: String, default: '' },
    size: { type: String, default: 'md', validator: (value) => ['md', 'sm'].includes(value) },
    modelValue: { required: true, default: '' },
    required: { type: Boolean, default: false },
    tip: { type: String, default: '' },
    errors: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },

  data() {
    return { currentValue: '' };
  },

  mounted() {
    this.$refs['number-input'].value = this.modelValue;
  },

  methods: {
    formatNumber(e) {
      if ((e.target.value && !e.target.value.includes('-') && !e.target.value.includes('e')) || e.data === null) this.$emit('update:modelValue', e.target.value);
      else e.target.value = this.modelValue;
    }
  },
}
</script>
