<template>
  <div>
    <h2 class="mb-4">
      Рекомендуемые курсы для компаний
      <instruction block="company"></instruction>
    </h2>
    <v-select
      v-model:model-value="recommendedCourses"
      :options="courses"
      taggeble
      multiple
      label="name"
      class="mb-4"
    ></v-select>
    <button @click.prevent="setRecommendedCourses" class="btn btn-primary">Сохранить</button>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import Instruction from "./Instruction";

export default {
  name: "corp_recommended",

  components: {
    VueTagsInput,
    Instruction,
  },

  data() {
    return {
      recommendedCourses: [],
      courses: [],
    };
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.getCourses();
      this.getRecommendedCourses();
    },

    async getCourses() {
      const response = await this.$apiClient.get("/v2/courses");

      this.courses = response.data.courses;
    },

    async getRecommendedCourses() {
      const response = await this.$apiClient.get(
        "/v2/courses/recommended/corporate"
      );

      this.recommendedCourses = response.data.courses;
    },

    async setRecommendedCourses() {
      const data = [];

      this.recommendedCourses.forEach((course) => {
        data.push(course.id);
      });

      const response = await this.$apiClient.post(
        "/v2/courses/recommended/corporate",
        { course_ids: data }
      );

      this.$root.$bvToast.toast("Рекомендуемы курсы для компаний сохранены", {
        title: "Уведомление",
        toaster: "b-toaster-top-right",
        variant: "primary",
        autoHideDelay: 3000,
      });
    },
  },
};
</script>