<template>
  <div class="container">
    <h1 class="mb-4 h2 fw-bold">Ревьюеры</h1>
    <template v-if="reviewers">
      <div class="row">
        <ReviewersCreating class="col-6" />
      </div>
      <hr class="mt-0" />
      <div class="row my-3">
        <form
          class="col-6 input-group"
          @submit.prevent="finishSearch = search"
        >
          <input
            type="search"
            v-model="search"
            class="form-control bg-transparent"
            placeholder="Поиск по логину, ФИО, e-mail ревьюера, названию курса"
            :disabled="!reviewers.length"
          />
          <button
            class="btn btn-primary"
            :class="!reviewers.length && 'btn-secondary'"
            :disabled="!reviewers.length"
          >
            Поиск
          </button>
        </form>
      </div>
      <table
        class="table table-striped align-middle"
        v-if="filteredReviewers.length"
      >
        <thead>
          <tr>
            <th>Логин</th>
            <th>ФИО</th>
            <th>E-mail</th>
            <th>Связанный курс</th>
            <th>Проверено</th>
            <th>Общее время</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="reviewer in filteredReviewers"
            :key="reviewer.id"
          >
            <td>{{ reviewer.user.login }}</td>
            <td>
              <div class="d-flex flex-column">
                <router-link :to="`/reviewers/${reviewer.id}`">
                  {{ reviewer.user.full_name.trim() || 'Перейти к ревьюеру' }}
                </router-link>
                <router-link
                  :to="`/users/${reviewer.user.id}/edit`"
                  class="text-danger"
                  v-if="!reviewer.user.full_name.trim()"
                >
                  Указать имя пользователя
                </router-link>
              </div>
            </td>
            <td>{{ reviewer.user.email }}</td>
            <td>
              <router-link :to="`/courses/${reviewer.course.url}/edit`">
                {{ reviewer.course.name }}
              </router-link>
            </td>
            <td>{{ reviewer.total_reviewed }}</td>
            <td>{{ totalTime(reviewer.total_spend_minutes) }}</td>
            <td>
              <button
                class="btn btn-sm btn-outline-danger"
                @click="deleteReviewer(reviewer.id)"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="card p-5"
        v-else
      >
        <div class="card-body p-5 h3 text-center text-muted">
          {{ stubText }}
        </div>
      </div>
    </template>
    <div
      class="loader text-center"
      v-else
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import { makeToast } from '@/custom_functions/custom_functions';
import ReviewersCreating from '@/components/reviewers/ReviewersCreating.vue';

export default {
  name: 'ReviewersIndex',

  components: { ReviewersCreating },

  data() {
    return {
      search: null,
      finishSearch: null,
    };
  },

  computed: {
    reviewers() {
      return this.$store.getters['reviewers/getReviewers'];
    },

    filteredReviewers() {
      if (!this.finishSearch) return this.reviewers;
      const search = this.finishSearch.toLowerCase();
      return this.reviewers.filter(
        (reviewer) =>
          reviewer.user.login.toLowerCase().includes(search) ||
          reviewer.user.full_name.toLowerCase().includes(search) ||
          reviewer.user.email.toLowerCase().includes(search) ||
          reviewer.course.name.toLowerCase().includes(search),
      );
    },

    stubText() {
      return this.finishSearch ? 'Совпадений не найдено' : 'Нет ревьюеров';
    },
  },

  created() {
    this.$store.dispatch('reviewers/fetchReviewers');
    this.$store.dispatch('users/fetchUsers');
    this.$store.dispatch('courses/fetchCoursesOmitPagination');
  },

  methods: {
    totalTime(totalMinutes) {
      const hours = ~~(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      if (hours) return `${hours} ч ${minutes} мин`;
      if (minutes) return `${minutes} мин`;
      return 0;
    },

    async deleteReviewer(id) {
      await this.$apiClient.delete(`/v2/reviewers/${id}`);
      const index = this.reviewers.findIndex((reviewer) => reviewer.id === id);
      this.reviewers.splice(index, 1);
      makeToast('Ревьюер удалён', 'success');
    },
  },
};
</script>
