<template>
  <div>
    <label for="product-icon-url" class="mb-2">Иконка</label>
    <input
      id="product-icon-url"
      :accept="formatsString"
      class="d-none"
      type="file"
      @change="setIcon"
    />

    <div class="d-flex align-items-start">
      <button class="btn btn-link me-2" :disabled="!modelValue" @click="showIcon">
        <img :src="iconUrl" alt="Иконка" class="icon-img" />
      </button>

      <b-modal v-model="shownIconModal" hide-footer title="Иконка">
        <div class="d-flex justify-content-center">
          <img :src="iconUrl" class="icon-modal" alt="Иконка" />
        </div>
      </b-modal>

      <label class="input-group" for="product-icon-url">
        <span class="form-control">{{ inputText }}</span>
        <span class="btn btn-outline-secondary">{{ buttonText }}</span>
      </label>
    </div>
    <span class="form-text">Можно взять из лендинга на Тильде</span>
  </div>
</template>

<script setup>
import { makeToast, readFile } from '@/custom_functions/custom_functions';

import { computed, ref, toRefs } from 'vue';

const props = defineProps({ modelValue: { required: true } });
const { modelValue } = toRefs(props);

const emits = defineEmits([ 'update:modelValue' ]);

const shownIconModal = ref(false);

const formats = ['image/jpeg', 'image/png', 'image/svg+xml'];

const formatsString = formats.join(', ');

const iconUrl = computed(() => {
  if (modelValue.value) {
    if (modelValue.value.includes('data:image')) return modelValue.value || '';
    return `${process.env.VUE_APP_API_URL}/${modelValue.value}`;
  }
  return require('@/assets/icon-stub.svg');
});

const showIcon = () => shownIconModal.value = true;

function setIcon(event) {
  if (event.target.files.length) {
    const file = event.target.files[0];

    if (!formats.includes(file.type)) {
      makeToast(
        `Формат картинки не поддерживается. Поддерживаемые форматы ${formatsString}`,
        'danger',
      );
    } else if (file.size > 102400) makeToast('Размер файла не должен превышать 100кб', 'danger');
    else readFile(file, (result) => emits('update:modelValue', result));
  }
}

const inputText = computed(() => modelValue.value ? 'Файл выбран' : 'Файл не выбран');

const buttonText = computed(() => modelValue.value ? 'Изменить файл' : 'Выбрать файл');
</script>

<style scoped>
.icon-modal {
  max-width: 100%;
}
.icon-img {
  width: 50px;
}
</style>

