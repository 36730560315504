<template>
  <div>
    <ExportCourseUnitsButton
      v-if="coursesExceptSelf"
      :courses="coursesExceptSelf"
      :course_units="course.visible_units"
      :src_course="course"
    />
    <button v-else class="btn btn-light mb-3" disabled>
      <b-spinner small class="me-1" />
      Экспортировать юниты
    </button>
    <div
      v-for="(unit, unitIndex) in course.visible_units"
      :id="`unit-${unit.id}`"
      :key="unit.id"
      class="card mb-3 p-3"
    >
      <div class="mb-3 d-flex no-block justify-content-between align-top">
        <div class="d-flex flex-column">
          <router-link :to="`/units/${unit.id}/edit?course=${course.url}`" class="h4 mb-0">
            {{ unitIndex + 1 }}. {{ unit.name }}
          </router-link>
          <span v-if="unit.has_submissions_deadline" class="text-secondary">
            Дедлайн:
            {{ formatDateTimeInterval(unit.submissions_start_at, unit.submissions_deadline_at) }}
          </span>
          <span
            v-if="unit.publication_status === 'draft' && unit.available_at"
            class="text-secondary"
          >
            Автопубликация: {{ formatDateWithoutYear(unit.available_at) }}
          </span>
        </div>
        <div>
          <button
            class="btn btn-outline-danger"
            @click.prevent="deleteUnitModalShown[unitIndex] = true"
          >
            Удалить юнит
          </button>
        </div>
        <BinaryModal
          v-model:model-value="deleteUnitModalShown[unitIndex]"
          :title="`Юнит «${unit.name}» будет удалён`"
          @confirm="deleteUnit(unit)"
        />
      </div>
      <table
        v-if="unit.lessons && unit.lessons.length"
        class="table table-hover table-responsive-lg"
      >
        <thead>
          <tr>
            <th scope="col" class="w-75">Название</th>
            <th scope="col" class="w-5">ID</th>
            <th scope="col" class="w-15">Статус</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(lesson, index) in unit.lessons" :key="lesson.id" class="mb-3">
            <td class="w-75">
              <router-link
                :to="`/lessons/${lesson.id}/step/${lesson.first_step_id}?course=${course.url}&unit=${unit.id}`"
              >
                {{ unitIndex + 1 }}.{{ index + 1 }}. {{ lesson.title }}
              </router-link>
            </td>
            <td class="w-5">{{ lesson.id }}</td>
            <td class="w-15">{{ formatLessonStatus(lesson.publication_status) }}</td>
            <td class="text-end">
              <a href="#" @click.prevent="deleteLessonModalShown[unitIndex][index] = true">
                <font-awesome-icon icon="trash" class="text-danger" />
              </a>
              <b-modal v-model="deleteLessonModalShown[unitIndex][index]" hide-footer>
                <template v-slot:modal-title>Подтвердите действие</template>
                <div class="d-block text-center">
                  <h4>Урок «{{ lesson.title }}» будет удалён</h4>
                </div>
                <b-button
                  class="mt-3 me-2"
                  block
                  variant="outline-danger"
                  @click.prevent="deleteLesson(unitIndex, lesson)"
                >
                  Удалить
                </b-button>
                <b-button
                  class="mt-3"
                  block
                  variant="outline-secondary"
                  @click="deleteLessonModalShown[unitIndex][index] = false"
                >
                  Отменить
                </b-button>
              </b-modal>
            </td>
          </tr>
        </tbody>
      </table>

      <div>
        <button class="btn btn-outline-primary" @click="newLessonModalShown[unitIndex] = true">
          Добавить урок
        </button>
        <b-modal v-model="newLessonModalShown[unitIndex]" hide-footer>
          <template v-slot:modal-title>Подтвердите действие</template>
          <div class="d-block text-center">
            <input
              v-model.lazy="newLessonTitle"
              type="text"
              class="form-control"
              placeholder="Новый урок"
            />
          </div>
          <b-button
            class="mt-3 me-2"
            block
            variant="outline-primary"
            @click="createLesson(unit.id)"
          >
            Подтвердить
          </b-button>
          <b-button
            class="mt-3"
            block
            variant="outline-secondary"
            @click="newLessonModalShown[unitIndex] = false"
          >
            Отменить
          </b-button>
        </b-modal>
      </div>
    </div>

    <div>
      <router-link
        class="btn btn-outline-primary me-2"
        :to="`/units/new?courseId=${course.id}&courseUrl=${course.url}`"
      >
        Добавить юнит
      </router-link>
      <button
        v-if="course.visible_units && course.visible_units.length > 1"
        id="button-units-sort"
        class="btn btn-outline-primary"
        @click="openModalUnitsSort"
      >
        Изменить порядок юнитов
      </button>
      <b-modal v-model="unitsSortModalShown" hide-footer>
        <template v-slot:modal-title>Порядок шагов</template>
        <draggable v-model:model-value="unitsSort" item-key="id">
          <template #item="{ element }">
            <div class="card mb-2">
              <div class="card-body d-flex align-items-center">
                <font-awesome-icon icon="grip-vertical" class="text-muted drag-icon me-3" />
                <span>{{ element.index + 1 }}. {{ element.name }}</span>
              </div>
            </div>
          </template>
        </draggable>
        <button class="btn btn-primary mt-3" @click="changeUnitsSort">Сохранить</button>
      </b-modal>
    </div>
  </div>
</template>

<script setup>
import BinaryModal from '@/components/BinaryModal';
import ExportCourseUnitsButton from '@/components/courses/ExportCourseUnitsButton';

import { formatLessonStatus, makeToast } from '@/custom_functions/custom_functions';
import { formatDateTimeInterval, formatDateWithoutYear } from '@/custom_functions/format_functions';

import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import { apiClient } from '@/custom_functions/api_client';

const store = useStore();

const course = computed(() => store.getters['course/getCourse']);

store.dispatch('courses/fetchCoursesOmitPagination', { withoutContainers: true });
const courses = computed(() => store.getters['courses/getCoursesOmitPagination']);

const coursesExceptSelf = computed(() => {
  if (!courses.value) return null;

  return courses.value.filter((c) => c.id !== course.value.id);
});

const newLessonTitle = ref(null);
const unitsSort = ref(null);
const deleteUnitModalShown = ref(new Array(course.value.visible_units.length).fill(false));
const deleteLessonModalShown = ref(
  course.value.visible_units.map((unit) => new Array(unit.lessons.length).fill(false)),
);
const newLessonModalShown = ref(new Array(course.value.visible_units.length).fill(false));
const unitsSortModalShown = ref(false);

async function deleteUnit(unit) {
  await apiClient.delete(`/v1/units/${unit.id}`);
  const unitIndex = course.value.visible_units.indexOf(unit);
  deleteUnitModalShown.value[unitIndex] = false;
  course.value.visible_units.splice(unitIndex, 1);
  deleteUnitModalShown.value.splice(unitIndex, 1);
  newLessonModalShown.value.splice(unitIndex, 1);
}

async function deleteLesson(unitIndex, lesson) {
  await apiClient.delete(`/v1/lessons/${lesson.id}`);
  const lessonIndex = course.value.visible_units[unitIndex].lessons.indexOf(lesson);
  deleteLessonModalShown.value[unitIndex][lessonIndex] = false;
  course.value.visible_units[unitIndex].lessons.splice(lessonIndex, 1);
  deleteLessonModalShown.value[unitIndex].splice(lessonIndex, 1);
}

async function createLesson(unitId) {
  let postData = { lesson: { title: newLessonTitle.value, unit_id: unitId } };

  let response = await apiClient.post('/v1/lessons', postData);

  let currentUnit = course.value.visible_units.find((obj) => obj.id == unitId);
  currentUnit.lessons.push(response.data.lesson);

  newLessonTitle.value = null;
  const unitIndex = course.value.visible_units.findIndex((unit) => unit.id === unitId);
  newLessonModalShown.value[unitIndex] = false;
}

function openModalUnitsSort() {
  unitsSort.value = course.value.visible_units.map((unit, index) => ({ ...unit, index }));
  unitsSortModalShown.value = true;
}

async function changeUnitsSort() {
  const units = unitsSort.value.map((unit, index) => ({ id: unit.id, weight: index }));
  await apiClient.patch(`/v1/courses/${course.value.url}/units/reorder`, { units });
  course.value.visible_units = unitsSort.value;
  unitsSortModalShown.value = false;
  makeToast('Новый порядок юнитов успешно сохранен.', 'success');
}
</script>

<style scoped>
.w-5 {
  width: 5%;
}
.w-15 {
  width: 15%;
}
</style>
