<template>
  <div class="d-flex w-100 support-wrapper">
    <div class="h-100 list">
      <div class="p-2">
        <input
          type="search"
          class="form-control w-100 mb-2"
          placeholder="Поиск"
          v-model="params.search"
          @search="searchByParams()"
        />
        <div class="d-flex flex-row">
          <div class="me-2">
            <div class="btn-group">
              <button
                type="button"
                class="btn"
                :class="
                  params.scope === 'unresolved'
                    ? 'btn-dark'
                    : 'btn-outline-dark'
                "
                @click="changeScope('unresolved')"
              >
                Новые
              </button>
              <button
                type="button"
                class="btn"
                :class="params.scope === 'my' ? 'btn-dark' : 'btn-outline-dark'"
                @click="changeScope('my')"
              >
                Мои
              </button>
              <button
                type="button"
                class="btn"
                :class="!params.scope ? 'btn-dark' : 'btn-outline-dark'"
                @click="changeScope(null)"
              >
                Все
              </button>
            </div>
          </div>
          <div class="w-100">
            <CustomSelect
              v-model:model-value="commercial"
              :options="filters"
              id="lesson-publication-status"
              class="mb-3"
            />
          </div>
        </div>
      </div>
      <SupportConversationsList />
    </div>
    <SupportConversation class="h-100 chat border-left" />
  </div>
</template>

<script>
import CustomSelect from '@/components/CustomSelect.vue';
import SupportConversationsList from '@/components/support/SupportConversationsList.vue';
import SupportConversation from '@/components/support/SupportConversation.vue';
import { apiClient } from '../../custom_functions/api_client';

export default {
  name: 'SupportIndex',

  components: { SupportConversationsList, SupportConversation, CustomSelect },

  data() {
    return {
      params: {
        search: null,
        scope: null,
        before: new Date(),
      },
      commercial: null,
      filters: [
        { value: null, name: 'Любые' },
        { value: false, name: 'Бесплатные' },
        { value: true, name: 'Коммерческие' },
      ],
    };
  },

  async created() {
    this.$store.dispatch('profile/fetchCurrentUser');
    await this.$store.dispatch('support/fetchConversations', this.params);
    this.$store.dispatch('support/connectConversationsCunsomer');
    if (this.$route.query.user_email && this.$route.path == '/support') {
      let response = await apiClient.get('v2/conversations', {
        params: { search: this.$route.query.user_email },
      });
      let usersConversations = response.data.conversations[0];
      this.$router.push(
        this.$route.fullPath.replace(
          'support',
          `support/${usersConversations.id}`,
        ),
      );
      this.$store.dispatch(
        'support/changeCurrentConversation',
        Number(usersConversations.id),
      );
    } else {
      this.$store.dispatch(
        'support/changeCurrentConversation',
        Number(this.$route.params.id),
      );
    }
  },

  unmounted() {
    this.$store.dispatch('support/disconnectConversationsCunsomer');
    this.$store.commit('support/updateConversations', null);
  },

  methods: {
    changeScope(value) {
      this.params.scope = value;
      this.searchByParams();
    },
    searchByParams() {
      this.$store.commit('support/updateConversations', null);
      this.$store.dispatch('support/fetchConversations', {
        ...this.params,
        commercial: this.commercial,
      });
    },
  },

  watch: {
    commercial() {
      this.searchByParams();
    },
  },
};
</script>

<style scoped>
.list {
  width: 40%;
}
.chat {
  width: 60%;
}

.support-wrapper {
  height: 100vh;
  margin: -24px 0;
}
</style>
