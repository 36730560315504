<template>
  <div class="container">
    <h2 class="mb-4">Спикеры</h2>
    <SpeakersArchiveFilter class="mb-3" v-model:model-value="filteredSpeakers" v-if="speakers" />
    <div class="row">
      <div class="mb-3 col-6" v-if="usersWithoutSpeaker">
        <template v-if="usersWithoutSpeaker.length">
          <label for="new-speaker-email" class="form-label">Добавить спикера:</label>
          <v-select v-model:model-value="newUser" id="new-speaker-email" placeholder="Почта спикера"
            :options="usersWithoutSpeaker" label="email"
            @search="(search) => { $store.dispatch('users/fetchUsersByEmail', search) }" />
        </template>
        <span v-else>Нет пользователей для создания спикера.<br /><router-link to="/users/new">Добавьте нового
            пользователя.</router-link></span>
      </div>
    </div>
    <template v-if="filteredSpeakers">
      <div class="row" v-if="filteredSpeakers.length">
        <div v-for="(speaker, index) in filteredSpeakers" :key="index" class="col-6 d-flex">
          <SpeakerCard :speaker="speaker" class="flex-grow-1 mb-3" :isNew="!speaker.id"
            @change-speaker="filteredSpeakers[index] = $event" @delete-speaker="deleteSpeaker(index)" />
        </div>
      </div>
      <span v-else>Не найден ни один спикер. Добавьте спикера, чтобы он отобразился в списке.</span>
    </template>
    <div v-else class="loader text-center">
      <b-spinner />
    </div>
  </div>
</template>

<script>
import SpeakerCard from '@/components/speakers/SpeakerCard.vue';
import SpeakersArchiveFilter from '@/components/speakers/SpeakersArchiveFilter.vue';

export default {
  name: 'SpeakersIndex',

  components: {
    SpeakerCard,
    SpeakersArchiveFilter,
  },

  data() {
    return {
      filteredSpeakers: null,
      newUser: null,
    };
  },

  computed: {
    usersWithoutSpeaker() {
      const users = this.$store.getters['users/getUsers'];
      return users && this.speakers ? users.filter((user) => !this.speakers.find((speaker) => speaker.user && user.id === speaker.user.id)) : null;
    },

    speakers() {
      return this.$store.getters['speakers/getSpeakers'];
    },
  },

  created() {
    this.$store.dispatch('speakers/fetchSpeakers');
    this.$store.dispatch('courses/fetchCoursesOmitPagination', { withoutContainers: true });
    this.$store.dispatch('users/fetchUsers');
  },

  methods: {
    createSpeaker(value) {
      this.speakers.push({
        full_name: value.full_name,
        description: '',
        course_ids: [],
        photo: null,
        user: value,
        user_id: value.id,
      });
    },

    deleteSpeaker(index) {
      this.speakers.splice(index, 1);
    },
  },

  watch: {
    newUser(value) {
      if (this.newUser) this.createSpeaker(value);
    },
  },
};
</script>
