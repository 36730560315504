<template>
  <form @submit.prevent>
    <div v-if="container.course_type === 'subscription'" class="row">
      <div class="col-6 mb-3">
        <label for="container-external-url" class="form-label">
          Ссылка на подписку на Тильде{{ container.publication_status === 'published' ? '*' : '' }}
        </label>
        <input
          id="container-external-url"
          v-model.trim="container.external_url"
          type="text"
          class="form-control"
          :class="errors.external_url && 'is-invalid'"
        />
        <span v-for="error, index in errors.external_url" :key="index" class="invalid-feedback">
          {{ error }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6 mb-3">
        <label for="container-description" class="form-label">
          Краткое описание для каталога и главной*
        </label>
        <textarea
          id="container-description"
          v-model="container.description"
          rows="2"
          class="form-control"
          maxlength="100"
          :class="errors.description && 'is-invalid'"
        />
        <span v-for="(error, index) in errors.description" :key="index" class="invalid-feedback">
          {{ error }}
        </span>
        <span class="form-text">Максимум 100 символов</span>
      </div>
    </div>
    <div class="row">
      <ProductIconInput v-model:model-value="container.icon_url" class="col-6 mb-3" />
    </div>
    <div class="row">
      <CustomSelect
        id="container-experience-level"
        v-model:model-value="container.experience_level"
        label="Уровень подготовки"
        :options="experienceLevelOptions"
        :errors="errors.experience_level"
        class="col-6 mb-3"
      />
    </div>
    <div class="row">
      <CustomSelect
        id="container-direction-ids"
        v-model:model-value="container.direction_ids"
        label="Направления"
        required
        :options="directionIdsOptions"
        :errors="errors.direction_ids"
        class="col-12 mb-3"
        multiple
        tip="
          Можно выбрать несколько направлений. Советуем заполнить,
          чтобы студент увидел этот комплект/подписку, когда фильтрует каталог по направлениям
        "
      />
    </div>
    <CustomSelect
      id="course-tags"
      v-model:model-value="container.tag_list"
      :options="tagsOptions"
      label="Теги (для отображения рекомендуемых курсов на главной)"
      class="mb-3"
      multiple
    />
    <div class="row" v-if="['bundle', 'container'].includes(container.course_type)">
      <div class="col-12 mb-3">
        <label for="container-extended-description" class="form-label">
          Расширенное описание (для продающей страницы)
        </label>
        <Editor
          id="container-extended-description"
          v-model:model-value="container.extended_description"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import ProductIconInput from '@/components/courses/products/ProductIconInput';
import CustomSelect from '@/components/CustomSelect';

import { useCustomSelectOptions } from '@/composables/use_custom_select_options';

const store = useStore();

const container = computed(() => store.getters['container/getContainer']);

const errors = computed(() => store.getters['container/getErrors']);

const tags = computed(() => store.getters['tags/getTags']);
const tagsOptions = computed(() => useCustomSelectOptions(tags.value));

const experienceLevelOptions = [
  { value: 1, name: 'Войти в IT' },
  { value: 2, name: 'С опытом' },
];

const directions = computed(() => store.getters['directions/getDirections']);
const directionIdsOptions = computed(() => useCustomSelectOptions(directions.value, 'id', 'name'));
</script>
