<template>
  <div class="d-flex align-items-baseline">
    <router-link class="btn btn-sm btn-outline-primary" :class="preAnswerLink === '' && 'disabled'" :to="preAnswerLink">
      <font-awesome-icon icon="chevron-left" />
    </router-link>
    <span class="mx-2">{{ answerIndex + 1 }}/{{ step.attempts.length }}</span>
    <router-link class="btn btn-sm btn-outline-primary" :class="nextAnswerLink === '' && 'disabled'" :to="nextAnswerLink">
      <font-awesome-icon icon="chevron-right" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ReviewerAnswerSwitch',

  computed: {
    step() {
      return this.$store.getters['reviewers/getStep'];
    },

    answer() {
      return this.$store.getters['reviewers/getAnswer'];
    },

    answerIndex() {
      if (this.step && this.answer) return this.step.attempts.findIndex((answer) => answer.id === this.answer.id);
      return null;
    },

    preAnswerLink() {  
      if (this.step && this.answerIndex !== null && this.answerIndex !== 0) {
        return `/reviewers/${this.$route.params.reviewerId}/steps/${this.step.id}/answers/${this.step.attempts[this.answerIndex - 1].id}`;
      }
      return '';
    },

    nextAnswerLink() {  
      if (this.step && this.answerIndex !== null && this.answerIndex !== this.step.attempts.length - 1) {
        return `/reviewers/${this.$route.params.reviewerId}/steps/${this.step.id}/answers/${this.step.attempts[this.answerIndex + 1].id}`
      }
      return '';
    },
  },
};
</script>
