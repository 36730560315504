<template>
  <div v-if="script" class="px-3">
    <div v-if="stand_session">
      <div class="d-flex align-items-center">
        <h2 class="me-2">Стенд</h2>
        <router-link :to="{name: 'stands_sessions_show', params: {id: script.runnable_id}}">
          <font-awesome-icon icon="link"></font-awesome-icon>
        </router-link>
      </div>
      <div class="d-flex align-items-end">
        <span class="me-2 fw-bold">Пользователь:</span>
        <span>{{stand_session.student_email}}</span>
      </div>
      <div class="d-flex align-items-end">
        <span class="me-2 fw-bold">Логин:</span>
        <span>{{stand_session.login}}</span>
      </div>
      <div class="d-flex align-items-end">
        <span class="me-2 fw-bold">Курс:</span>
        <span>{{stand_session.course_name}}</span>
      </div>
      <div class="d-flex align-items-end">
        <span class="me-2 fw-bold">Урок:</span>
        <span>{{stand_session.unit_name}}</span>
      </div>
    </div>
    <div v-else>
      <h2>Скрипт</h2>
    </div>
    <div class="mb-4 d-flex align-items-end">
      <span class="me-2 fw-bold">Запущен:</span>
      <span>{{ formatDateTime(script.created_at) }}</span>
    </div>

    <pre class="bg-dark border-dark card text-white">
      <div class="card-body">
        {{script.output}}
      </div>
    </pre>
  </div>
  <div v-else class="loader text-center">
    <b-spinner></b-spinner>
  </div>
</template>
<script>
import { formatDateTime } from "@/custom_functions/custom_functions.js";

export default {
  name: "script_runners_show",

  data() {
    return {
      script: null,
      stand_session: null,
    };
  },

  created() {
    this.init();
  },

  methods: {
    formatDateTime,
    
    async init() {
      await this.getScript();
      this.script.runnable_type === "StandSession" && await this.getStandSession();
    },

    async getScript() {
      const response = await this.$apiClient.get(
        `/v1/script_runners/${this.$route.params.id}`
      );

      this.script = response.data.script_runner;
    },

    async getStandSession() {
      const response = await this.$apiClient.get(
        `/v1/stand_sessions/${this.script.runnable_id}`
      );

      this.stand_session = response.data;
    },
  },
};
</script>