<template>
  <div>
    <div class="d-flex mb-4">
      <form class="input-group me-2 w-50"
        @submit.prevent="store.dispatch('course/fetchParticipations', participationsParams)">
        <input v-model="query" placeholder="Почта или логин студента" type="search" class="form-control" />
        <button type="submit" class="btn btn-primary input-group-control">Найти</button>
      </form>

      <div class="btn-toolbar" role="toolbar">
        <div class="btn-group" role="group">
          <button v-if="participations.length" @click.prevent="downloadProgress" id="download-progress-button"
            class="btn btn-outline-primary">
            О прогрессе
          </button>

          <button v-if="participations.length" @click.prevent="downloadProgressByUnit"
            id="download-progress-by-unit-button" class="btn btn-outline-primary">
            По модулям
          </button>
          <div v-b-tooltip.hover title="Скачать отчет о прогрессе или по модулям" class="cursor-default btn btn-primary">
            <font-awesome-icon icon="circle-down" />
          </div>
        </div>
      </div>

    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th width="1">Логин</th>
          <th>Студент</th>
          <th width="200">Компания</th>
          <th>Даты</th>
          <th width="100">Тестер</th>
          <th width="100">Gitlab</th>
          <th>&nbsp;</th>
        </tr>
      </thead>

      <tbody id="participations-list">
        <tr v-for="participation in participations" :key="participation.id">
          <td>
            <router-link :to="`/users/${participation.student_id}/edit`">
              {{ participation.student_login }}
            </router-link>
          </td>
          <td>
            {{ participation.student_name }}
            <template v-if="participation.certificate">
              <font-awesome-icon :id="'certified-' + participation.id"
                :icon="certificateIcon(participation.certificate.legal_number)" size="sm" />
              <b-tooltip :target="`certified-${participation.id}`" triggers="hover">
                Пользователь имеет {{
                  documentType(participation.certificate.legal_number)
                }} по данному курсу
              </b-tooltip>
            </template>
          </td>
          <td>{{ participation.company_name }}</td>
          <td>
            <template v-if="participation.date_from && participation.date_to">
              {{ formatDate(participation.date_from) }} - {{ formatDate(participation.date_to) }}
            </template>
          </td>
          <td>
            <input v-model="participation.is_tester" type="checkbox" @change="toggleTester(participation)" />
          </td>
          <td>{{ participation.student_gitlab_user_id }}</td>
          <td class="d-flex justify-content-end">
            <button class="btn btn-outline-danger btn-sm" @click="deleteUserParticipation(participation.id)">
              <font-awesome-icon icon="trash" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <b-pagination v-if="totalPages > 1" :total-rows="participations.length * totalPages" :per-page="participations.length"
      class="position-sticky" @change="(page) => changePage(page)" />
  </div>
</template>

<script setup>
import { formatDate, makeToast } from '@/custom_functions/custom_functions';

import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { apiClient } from '@/custom_functions/api_client';

const store = useStore();

const route = useRoute();

const query = ref('');

const participations = computed(() => store.getters['course/getParticipations']);

const totalPages = computed(() => store.getters['course/getParticipationsTotalPages']);

const participationsParams = computed(() => ({
  page: 1,
  search: query.value,
  id: route.params.id,
}));

store.dispatch('course/fetchParticipations', participationsParams.value);

async function toggleTester(participation) {
  const isTester = participation.is_tester;
  await apiClient.patch(`/v2/participations/${participation.id}`, { participation });
  makeToast(isTester
    ? 'Студент назначен тестером'
    : 'Студент исключен из группы тестеров', 'success',
  );
  store.dispatch('course/fetchParticipations', participationsParams.value);
}

async function deleteUserParticipation(participationId) {
  await apiClient.delete(`/v1/participations/${participationId}`);
  makeToast('Студент удален с курса', 'success');
  store.dispatch('course/fetchParticipations', participationsParams.value);
}

async function downloadProgress() {
  const response = await apiClient.get(
    `v2/courses/${route.params.id}/participations/summary.xlsx`,
    { responseType: 'arraybuffer' },
  );
  downloadFile(response.data, 'Отчёт по всем студентам.xlsx');
}

async function downloadProgressByUnit() {
  const response = await apiClient.get(
    `v2/courses/${route.params.id}/units_progress.csv`,
    { responseType: 'arraybuffer' },
  );
  downloadFile(response.data, 'Отчёт по модулям.csv');
}

function downloadFile(data, fileName) {
  const blob = new Blob([data], {
    type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
}

function certificateIcon(legalNumber) {
  return legalNumber ? 'user-graduate' : 'graduation-cap';
}

function documentType(legalNumber) {
  return legalNumber ? 'сертификат' : 'свидетельство';
}

const changePage = (page) => {
  store.dispatch('course/fetchParticipations', {
    page: page,
    search: query.value,
    id: route.params.id,
  });
}
</script>

<style scoped>
.pagination {
  bottom: 10px;
}
</style>
