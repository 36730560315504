<template>
  <div
    class="card"
    :class="!speaker.archived && !isNew && 'border-primary'"
  >
    <div class="card-body d-flex justify-content-between">
      <SpeakerAvatar
        class="me-2"
        :speaker="speaker"
        :show-file-name="isNew"
        v-model:model-value="photoDataUri"
        @update:model-value="!isNew && postData()"
      />

      <div class="flex-grow-1 me-3 speakers-form">
        <div class="speaker-text d-flex align-items-baseline">
          <template v-if="speaker.user">
            <h3
              class="h6 mb-1 fw-bold"
              v-if="speaker.user.full_name"
            >
              {{ speaker.user.full_name }}
            </h3>
            <router-link
              class="h6 mb-1 text-danger"
              v-else
              :to="`/users/${speaker.user.id}/edit`"
            >
              Укажите в профиле имя пользователя
              <font-awesome-icon icon="circle-exclamation" />
            </router-link>
          </template>
          <h3
            class="h6 mb-1 fw-bold"
            v-else
          >
            {{ speaker.full_name }}
          </h3>
        </div>

        <span
          class="text-primary mb-2 d-block speaker-text"
          v-if="speaker.user"
        >
          {{ speaker.user.email }}
        </span>
        <template v-else>
          <button
            class="text-danger mb-2 d-block speaker-text btn btn-link"
            v-if="!editMode"
            @click="editMode = true"
          >
            Укажите пользователя
            <font-awesome-icon icon="circle-exclamation" />
          </button>
          <v-select
            v-model:model-value="speaker.user"
            v-else-if="usersWithoutSpeaker"
            placeholder="Почта спикера"
            :options="usersWithoutSpeaker"
            label="email"
            @search="
              (search) => {
                $store.dispatch('users/fetchUsersByQuery', search);
              }
            "
            class="mb-2"
          />
        </template>

        <div class="d-flex flex-column">
          <label
            v-if="editMode"
            class="w-100"
          >
            Должность
            <input
              v-model="speaker.job_title"
              type="text"
              class="form-control mt-1"
            />
          </label>
          <span
            v-else
            class="text-secondary"
          >
            {{ speaker.job_title }}
          </span>

          <label
            v-if="editMode"
            class="w-100"
          >
            Место работы
            <input
              v-model="speaker.company"
              type="text"
              class="form-control mt-1"
            />
          </label>
          <span
            v-else
            class="text-secondary mb-1"
          >
            {{ speaker.company }}
          </span>
        </div>

        <label
          v-if="editMode"
          class="w-100"
        >
          О спикере
          <textarea
            v-model="speaker.description"
            class="form-control mb-1 mt-1 speaker-text"
            rows="4"
            maxlength="320"
          />
        </label>
        <textarea
          v-else
          class="form-control mb-1 mt-1 speaker-text"
          rows="4"
          v-model="speaker.description"
          disabled
        />

        <template v-if="courses">
          <div
            class="p-1 border-white"
            v-if="!editMode"
          >
            <span
              class="badge text-bg-primary speaker-course-badge me-1 p-1 mb-1 text-wrap text-start"
              v-for="courseId in speaker.course_ids"
              :key="courseId"
            >
              <div class="d-flex">
                <span class="me-1">{{ courseName(courseId) }}</span>
                <button
                  class="btn btn-block text-white p-0 m-0 d-inline w-auto speaker-remove-course-button"
                >
                  <font-awesome-icon
                    icon="xmark"
                    @click="removeCourse(courseId)"
                  />
                </button>
              </div>
            </span>
          </div>
          <label
            v-else
            class="w-100"
          >
            Курсы спикера
            <v-select
              v-model="speaker.course_ids"
              :options="courses"
              :reduce="(course) => course.id"
              label="name"
              taggable
              multiple
              :disabled="!editMode"
              class="mb-2 mt-1"
            />
          </label>
        </template>

        <button
          class="btn btn-primary"
          @click="saveSpeaker()"
          v-if="editMode"
        >
          Сохранить
        </button>
      </div>

      <div class="d-flex align-items-start">
        <button
          class="btn btn-block text-primary me-3 p-0 m-0 d-inline"
          v-if="!editMode"
          @click="editMode = true"
        >
          <font-awesome-icon
            :icon="['far', 'pen-to-square']"
            size="lg"
          />
        </button>
        <button
          class="btn btn-block text-danger p-0 m-0"
          @click="deleteSpeaker"
        >
          <font-awesome-icon
            icon="trash"
            size="lg"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SpeakerAvatar from '@/components/speakers/SpeakerAvatar.vue';
import { makeToast } from '@/custom_functions/custom_functions';

export default {
  name: 'SpeakerCard',

  components: {
    SpeakerAvatar,
  },

  props: {
    speaker: Object,
    isNew: Boolean,
  },

  data() {
    return {
      editMode: false,
      photoDataUri: null,
    };
  },

  computed: {
    usersWithoutSpeaker() {
      const users = this.$store.getters['users/getUsers'];
      const speakers = this.$store.getters['speakers/getSpeakers'];
      if (users && speakers)
        return users.filter(
          (user) =>
            !speakers.find(
              (speaker) => speaker.user && user.id === speaker.user.id,
            ),
        );
      return null;
    },

    courses() {
      return this.$store.getters['courses/getCoursesOmitPagination'];
    },
  },

  created() {
    this.editMode = this.isNew;
  },

  methods: {
    async deleteSpeaker() {
      if (!this.isNew)
        await this.$apiClient.delete(`/v2/speakers/${this.speaker.id}`);
      this.$emit('delete-speaker');
    },

    removeCourse(courseId) {
      let courseIndex = this.speaker.course_ids.indexOf(courseId);
      this.speaker.course_ids.splice(courseIndex, 1);
      this.postData();
    },

    courseName(id) {
      const course = this.courses.find((course) => course.id === id);
      return course ? course.name : '';
    },

    async postData() {
      let speaker = this.speaker;
      if (this.speaker.user) speaker.user_id = this.speaker.user.id;
      if (this.photoDataUri) speaker.photo_data_uri = this.photoDataUri;
      let response;
      if (this.isNew)
        response = await this.$apiClient.post('/v2/speakers', { speaker });
      else
        response = await this.$apiClient.patch(
          `/v2/speakers/${this.speaker.id}`,
          { speaker },
        );
      this.$emit('change-speaker', response.data.speaker);
      this.editMode = false;
    },

    async saveSpeaker() {
      if (!this.speaker.full_name) makeToast('Введите имя спикера', 'danger');
      else if (!this.speaker.job_title)
        makeToast('Укажите должность спикера', 'danger');
      else if (!this.speaker.company)
        makeToast('Укажите место работы спикера', 'danger');
      else if (
        this.speaker.description.length < 2 ||
        this.speaker.description.length > 10000
      )
        makeToast(
          'Описание спикера должно составлять не меньше 2 и не больше 320 символов',
          'danger',
        );
      else if (!this.speaker.user) makeToast('Укажите пользователя', 'danger');
      else this.postData();
    },
  },
};
</script>

<style>
.speaker-text {
  padding: 0.1rem !important;
  height: auto;
}

.speaker-text:disabled {
  border-color: transparent !important;
  background-color: transparent !important;
}

.speakers-form textarea.form-control:disabled {
  resize: none;
}

.speakers-form .vs__selected {
  background-color: #007bff !important;
  cursor: default !important;
  border-color: transparent !important;
  color: white !important;
}

.speakers-form .vs__deselect svg {
  fill: white;
}

.speaker-course-badge {
  font-size: 16px !important;
  font-weight: normal !important;
  line-height: 1.4 !important;
}

.speaker-remove-course-button {
  line-height: 1 !important;
}
</style>
