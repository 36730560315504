import { computed } from 'vue';
import { useStore } from 'vuex';

export function useProductOwners() {
  const store = useStore();

  const productOwners = computed(() => store.getters['course/getProductOwners']);

  function getProductOwnerNameById(id) {
    const productOwner = store.getters['course/getProductOwnerById'](id);
    return productOwner ? productOwner.full_name : '';
  };

  const productOwnersOptions = computed(() => {
    if (productOwners.value) {
      return productOwners.value.map((po) => ({ value: po.id, name: getProductOwnerNameById(po.id) }));
    }
    return null;
  })

  return { productOwners, getProductOwnerNameById, productOwnersOptions };
}
