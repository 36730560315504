<template>
  <div class="container">
    <h2 class="mb-4">Новый вебинар</h2>
    <WebinarsForm :webinar="webinar" class="mb-3" />
    <button class="btn btn-primary me-3" @click="createWebinar">Сохранить</button>
    <router-link class="btn btn-secondary" to="/webinars/page_1">Отменить</router-link>
  </div>
</template>

<script>
import WebinarsForm from '@/components/webinars/WebinarsForm.vue';
import { makeToast, webinarPostData } from '@/custom_functions/custom_functions';
import { validateWebinar } from '@/custom_functions/validation_functions';

export default {
  name: 'WebinarsNew',

  components: { WebinarsForm },

  data() {
    return {
      webinar: {
        type: "YoutubeWebinar",
        data: { url: null },
        publication_status: "published",
        description: '',
      },
    };
  },

  created() {
    this.$store.dispatch('speakers/fetchSpeakers');
  },

  methods: {
    async createWebinar() {
      if (validateWebinar(this.webinar)) {
        await this.$apiClient.post('/v2/webinars', webinarPostData(this.webinar), { headers: { 'Content-Type': 'multipart/form-data' } });
        await this.$router.replace('/webinars/page_1');
        makeToast(`Вебинар "${this.webinar.name}" сохранен`, 'primary');
      }
    },
  },
};
</script>
