<template>
  <div class="d-inline">
    <font-awesome-icon
      :id="'instruction'+block"
      v-b-modal="'instruction'+block" 
      icon="circle-question" 
      style="width: 20px; height: 20px; cursor: pointer; margin-bottom: 0.2rem;" 
      class="text-muted"
    />
    <b-tooltip placement="right" :target="'instruction'+block">Инструкция</b-tooltip>
    <b-modal :id="'instruction'+block" title="Настройка рекомендуемых курсов" ok-only ok-variant="secondary" size="lg" scrollable title-tag='h3'>
      <b-tabs pills card v-model="activeTab">
        <b-tab title="Главная страница">
          <h4>Блок рекомендаций на главной странице slurm</h4>
          <span>В него подбираются 4 наиболее подходящих курса.</span>

          <h5 class="mt-4">Создание тегов</h5>
          <p>
            На странице <router-link to="/tags">Рекомендованные курсы</router-link> в разделе Теги вводим любое название тега и жмем "Enter". <br>
            Можно создать любое количество тегов.<br>
          </p>
          <img src="../assets/instruction/tag_creation.png">
          <p>
            После добавления теги нужно сохранить нажав кнопку "Сохранить" после раздела "Приоритет тегов".
          </p>

          <h5 class="mt-4">Назначение тегов курсу</h5>
          <p>
            В <router-link to="/courses">Курсах</router-link> выбираем нужный курс.  <br>
            На открывшейся странице редактирования в разделе "Теги" выбираем все теги соответствующие курсу.<br>
          </p>
          <img src="../assets/instruction/choice_courses_tag.png">
          <p>После этого сохраняем изменения нажав "Сохранить" внизу страницы</p>

          <h5 class="mt-4">Настройка приоритета</h5>
          <p>
            На странице <router-link to="/tags">Рекомендованные курсы</router-link> в разделе "Приоритет тегов" нажимаем "Добавить политику".<br>
            В появившейся строчке вводим 4 тега, которым должен соответствовать рекомендуемый курс.<br>
          </p>
          <p class="text-muted">
            Поля соответствуют следующим параметрам:<br>
            1 - тема(agile)<br>
            2 - формат(видеокурс/интенсив)<br>
            3 - версия<br>
            4 - другое<br>
          </p>
          <img src="../assets/instruction/priority_1.png">
          <p>
            Добавив несколько строчек, можно менять их местами. Так выстраивается порядок, в котором подбираются рекомендуемые курсы.<br>
            Ненужную строчку можно удалить, нажав крестик.
          </p>
          <img src="../assets/instruction/priority_2.png">
          <p class="text-muted">
            Рекомендуемые курсы подбираются по следующему алгоритму:<br>
            - в список рекомендуемых курсов добавляются курсы, у которых есть все теги из первой строчки;<br>
            - если рекомендуемых курсов набралось меньше 4, то добавляются курсы соответсвующие второй строчке;<br>
            - т.д., пока не наберутся 4 курса или не закончаться строчки;<br>
            - если у студента нет ни одного курса с хотя бы одним тегом из строчки, такая строчка игнорируется.<br>
          </p>
          <p>
            Настроенный приоритет необходимо сохранить.
          </p>

          <h5 class="mt-4">Настройка отдельного тега</h5>
          <p>
            Переходим в раздел <router-link to="/tags_policy">Настройка тегов</router-link> и выбираем тег, который хотим настроить.<br>
            Здесь можно отрегулировать 2 парамтра: политика тега и список тегов, курсы с которыми не нужно выводить в рекомендованных, если у пользователя есть курс с выбранным тегом.<br>
          </p>
          <p class="text-muted">
            Варианты политики:<br>
            <b>"Любые не купленные"</b> - показываем любые не купленные курсы, имеющие данный тегу.<br>
            <b>"Только последний не купленный"</b> - например, если есть 2 курса с тегом slurm, первый курс студент купил, а второй вышел позже, то этот курс попадет в рекомендации.<br>
            <b>"Не показывать"</b> - не показываем никакие курсы с данным тегом, если у студента есть хотя бы 1 купленный курс соответсвующий выбранному тегу.<br>
            <b>"Игнорировать"</b> - не показываем никакие курсы с данным тегом.<br>
          </p>
          <p>После настройки необходимо сохранить изменения.</p>  
        </b-tab>
        <b-tab title="Последний шаг курса">
          <h4>Блок рекомендаций на странице последнего шага курса</h4>
          <p>
            На странице <router-link to="/courses">Курсы</router-link> выбираем курс, который хотим настроить.<br>
            В настройках курса в поле "Рекомендуемые курсы" выбираем подходящие для рекомендаций курсы.<br>
            Допустим студент проходил курс "Слерм базовый", мы можем ему порекомендовать "Cлерм мега".
          </p>
          <img src="../assets/instruction/course.png">
          <p>После изменений нажимаем "Сохранить" внизу страницы.</p>
        </b-tab>
        <b-tab title="Копоративный кабинет">
          <h4>Блок рекомендаций в корпоративном кабинете</h4>
          <p>
            Чтобы настроить рекомендации для компаний, заходим на страницу <router-link to="/tags">Рекомендованные курсы</router-link>.<br>
            В разделе "Рекомендуемые курсы для команий" выбираем все курсы, которые хотим рекомендовать корпоративным клиентам.<br>
          </p>
          <img src="../assets/instruction/corporate.png">
          <p>После изменений нажимаем "Сохранить" внизу раздела. </p>
        </b-tab>
      </b-tabs>
      
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    block: {
      type: String,
      default: 'main'
    }
  },

  data() {
    return {
      activeTab: 0,
    }
  },

  created() {
    if (this.block === 'main') this.activeTab = 0
    if (this.block === 'course') this.activeTab = 1
    if (this.block === 'company') this.activeTab = 2
  }
}
</script>
<style scoped>
img {
  max-width: 100%;
  margin-bottom: 16px;
}
</style>