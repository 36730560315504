<template>
  <div>
    <div class="mb-3">
      <label for="step-regexp" class="mb-2">Верный ответ:</label>
      <textarea class="form-control" v-model="step.data.regexp" placeholder="Ответ" id="step-regexp" />
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" v-model.lazy="step.data.settings.use_regexp" id="step-use-regexp" />
      <label class="form-check-label" for="step-use-regexp">Проверять ответ студента с помощью регулярного выражения</label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="checkbox" v-model.lazy="step.data.settings.ignore_case" id="step-ignore-case" />
      <label class="form-check-label" for="step-ignore-case">Не различать прописные и заглавные буквы</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextTaskStep',

  props: { step: { type: Object, required: true } },
};
</script>
