<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <draggable
      v-if="currentDirections.length"
      v-model:model-value="currentDirections"
      tag="ul"
      class="list-unstyled"
      itemKey="id"
    >
      <template
        #item="{ element }"
        :id="element.id"
      >
        <li class="mb-4 d-flex align-items-center">
          <button
            class="btn btn-link text-muted"
            title="Изменить порядок"
          >
            <font-awesome-icon icon="grip-vertical" />
          </button>
          <DirectionsItem
            :direction="element"
            :index="element.index"
            class="flex-grow-1"
          />
        </li>
      </template>
    </draggable>
    <div
      v-else
      class="text-muted"
      key="stub"
    >
      Еще не создано ни одно направление
    </div>
  </transition>
</template>

<script>
import DirectionsItem from '@/components/directions/DirectionsItem';

export default {
  name: 'DirectionsList',

  components: { DirectionsItem },

  data() {
    return {
      currentDirections: null,
    };
  },

  computed: {
    directions() {
      return this.$store.getters['directions/getCurrentDirections'];
    },
  },

  created() {
    this.currentDirections = this.directions;
  },

  watch: {
    directions(value) {
      this.currentDirections = value;
    },

    currentDirections(value) {
      if (this.directions !== value)
        this.$store.dispatch('directions/changeCurrentDirections', value);
    },
  },
};
</script>

<style scoped>
li {
  margin-left: -12px;
}
</style>
