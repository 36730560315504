<template>
  <div class="container">
    <template v-if="reviewer">
      <h1 class="h2 fw-bold">{{ title }}</h1>
      <ReviewerCourse />
    </template>
    <div class="loader text-center" v-else>
      <b-spinner />
    </div>
  </div>
</template>

<script>
import ReviewerCourse from '@/components/reviewers/ReviewerCourse.vue';

export default {
  name: 'ReviewersShow',

  components: { ReviewerCourse },

  computed: {
    reviewer() {
      return this.$store.getters['reviewers/getReviewer'];
    },

    title() {
      return this.reviewer.user.full_name.trim() ? this.reviewer.user.full_name : this.reviewer.user.email;
    },
  },

  created() {
    this.$store.dispatch('reviewers/fetchReviewer', this.$route.params.reviewerId);
  },
};
</script>
