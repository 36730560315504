import validateNotEmptyField from './helpers/validate_not_empty_field';
import validatePositiveIntegerValue from './helpers/validate_positive_integer_value';

export default function validateOrder(order) {
  let result = {};

  if (!order) {
    // TODO: Throw exeption if value null or undefined
    const order = ['Заказ не может быть пустым'];
    result.order = order;
    return result;
  }

  const userEmail = validateNotEmptyField(order.userEmail);
  if (userEmail.length) result.userEmail = userEmail;

  const courseIds = validateNotEmptyField(order.courseIds);
  if (courseIds.length) result.courseIds = courseIds;

  const promoCode = validateNotEmptyField(order.promoCode);
  if (promoCode.length) result.promoCode = promoCode;

  const amount = validatePositiveIntegerValue(order.amount);
  if (amount.length) result.amount = amount;

  return result;
}
