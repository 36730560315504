import App from './App.vue';
import router from './router';
import BootstrapVue from 'bootstrap-vue';
import Axios from 'axios';
import store from './store';
import VueSelect from 'vue-select';
import draggable from 'vuedraggable';
import Editor from '@/components/Editor.vue';
import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import { errorHandling } from './custom_functions/custom_functions.js';
import { apiClient } from './custom_functions/api_client.js';
import { notifyErrbit } from './custom_functions/errbit.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/styles.css';
import 'prismjs/themes/prism.css';
import * as Sentry from '@sentry/vue';

library.add(fas);
library.add(far);

const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

app.config.errorHandler = notifyErrbit;
app.config.globalProperties.$http = Axios;
app.config.globalProperties.$errorHandling = errorHandling;
app.config.globalProperties.$apiClient = apiClient;

app.component('font-awesome-icon', FontAwesomeIcon);

app.component('v-select', VueSelect);

app.component('Editor', Editor);

app.component('draggable', draggable);
draggable.compatConfig = { MODE: 3 };

app.use(createMetaManager(), { refreshOnceOnNavigation: true });

app.use(BootstrapVue);

app.use(router);

app.use(store);

app.mount('#app');
