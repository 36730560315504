import { Notifier } from '@airbrake/browser';

let notifier = null;
const errbitKey = process.env.VUE_APP_ERRBIT_KEY
if (errbitKey) {
   notifier = new Notifier({
     projectId: errbitKey,
     projectKey: errbitKey,
     reporter: 'xhr',
     host: 'https://errbit.southbridge.io',
     environment: 'production'
   });
}

export const errbit = notifier;

export function notifyErrbit(err, vm, info) {
  if (errbit) {
    errbit.notify({
      error: err,
      params: { info: info }
    });
  }

  throw(err);
}
