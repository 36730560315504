<template>
  <div class="container">
    <transition name="fade" mode="out-in">
      <div v-if="answer && step">
        <Breadcrumbs :items="breadcrumbsItems" class="mb-2" />
        <div class="d-flex align-items-center mb-3">
          <h1 class="h2 fw-bold me-3 mb-0">
            Решение №{{ answer.id }} к уроку
            <u>{{ step.lesson.title }}, шаг {{ step.index }}</u>
          </h1>
          <div class="h4 mb-0">
            <span v-if="answer.correct" class="badge rounded-pill border border-success text-success">Решение зачтено</span>
            <span v-else-if="answer.correct === false" class="badge rounded-pill border border-warning text-warning">Нужна доработка</span>
            <span v-else-if="attemptIsExpired(answer.created_at)" class="badge rounded-pill border border-danger text-danger">
              Время проверки истекло
            </span>
            <span v-else-if="attemptIsRotting(answer.created_at)" class="text-warning" :id="`rotting-attempts-${answer.id}`">
              <font-awesome-icon icon="circle-exclamation" />
              <b-tooltip :target="`rotting-attempts-${answer.id}`" triggers="hover" placement="right">
                Меньше суток до окончания срока проверки
              </b-tooltip>
            </span>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-end mb-3">
          <div class="d-flex flex-column">
            <span class="mb-2">Студент: <b>{{ answer.student_full_name }}</b></span>
            <span>Получено {{ createdDate }}</span>
          </div>
          <ReviewerAnswerSwitch />
        </div>
        <hr>
        <ReviewerTaskText />
        <hr>
        <div class="my-3">
          <div class="mb-3 fw-bold">Решение учащегося:</div>
          <div class="bg-light p-3">{{ answer.answer_data.value }}</div>
        </div>
        <hr>
        <div v-if="answer.answer_data.answer">
          <div class="mb-3 fw-bold">Рецензия</div>
          <div>{{ answer.answer_data.answer }}</div>
        </div>
      </div>
      <div v-else class="loader text-center">
        <b-spinner></b-spinner>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { attemptIsRotting, attemptIsExpired } from '@/custom_functions/custom_functions';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ReviewerAnswerSwitch from '@/components/reviewers/ReviewerAnswerSwitch.vue';
import ReviewerTaskText from '@/components/reviewers/ReviewerTaskText.vue';

export default {
  name: 'ReviewerAnswer',

  components: { Breadcrumbs, ReviewerAnswerSwitch, ReviewerTaskText },

  computed: {
    ...mapGetters('reviewers', {
      answer: 'getAnswer',
      step: 'getStep',
    }),

    breadcrumbsItems() {
      if (this.step && this.answer) {
        return [
          { title: this.step.course.name, link: `/reviewers?course=${this.step.course.url}` },
          { title: this.step.unit.name, link: `/reviewers?course=${this.step.course.url}&unit=${this.step.unit.id}` },
          { title: this.step.lesson.title, link: `/reviewers?course=${this.step.course.url}&unit=${this.step.unit.id}` },
          { title: `Шаг ${this.step.index + 1}`, link: `/reviewers/${this.$route.params.reviewerId}/steps/${this.step.id}` },
          { title: `Решение студента: ${this.answer.student_full_name}` },
        ]
      }
      return [];
    },

    createdDate() {
      return moment(this.answer.created_at).locale('ru').utcOffset('+3:00').calendar();
    },
  },

  watch: {
    $route() {
      this.$store.dispatch('reviewers/fetchAnswer', this.$route.params);
      this.$store.dispatch('reviewers/fetchStep', this.$route.params);
    }
  },

  created() {
    this.$store.dispatch('reviewers/fetchAnswer', this.$route.params);
    this.$store.dispatch('reviewers/fetchStep', this.$route.params);
  },

  methods: { attemptIsRotting, attemptIsExpired },
};
</script>
