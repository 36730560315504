<template>
  <div class="row">
    <div class="mb-3 col-6">
      <label for="file-name" class="mb-2">Имя файла</label>
      <input type="text" class="form-control" v-model="report.name" id="file-name">
    </div>
    <div class="mb-3 col-4">
      <label for="cron" class="mb-2">Периодичность(в cron-формате)</label>
      <input type="text" class="form-control" :class="cronClasses.inputClass" v-model.lazy="report.cron" id="cron">
      <label :class="cronClasses.feedbackClass" v-if="report.cron" for="cron">{{ parsedCron }}</label>
    </div>
    <div class="mb-3 col-12">
      <label for="sql" class="mb-2">SQL запрос</label>
      <pre><textarea class="form-control" v-model="report.sql" id="sql"></textarea></pre>
    </div>
  </div>
</template>

<script>
import { toString } from 'cronstrue/i18n'
export default {
  name: 'ReportsForm',

  props: { report: Object },

  computed: {
    parsedCron() {
      return toString(this.report.cron, { locale: 'ru', throwExceptionOnParseError: false })
    },

    cronClasses() {
      if (this.report.cron) {
        if (this.parsedCron.includes('ошибка')) return { inputClass: 'is-invalid', feedbackClass: 'invalid-feedback' };
        else return { inputClass: 'is-valid', feedbackClass: 'valid-feedback' };
      } else return { inputClass: null, feedbackClass: null };
    },
  },
};
</script>