<template>
  <div class="px-3" >
    <div class="mb-4">
      <div class="d-flex no-block align-items-center justify-content-between">
        <h2 class="mb-0">Зоны</h2>

        <router-link class="btn btn-outline-primary" to="/zones/new">Добавить зону</router-link>
      </div>
    </div>

    <div v-if="zones" class="table-responsive">
      <table class="table table-sm table-hover table-responsive-lg align-middle">
        <thead>
          <tr>
            <th scope="col">Значение</th>
            <th scope="col">Расположение</th>
            <th scope="col">Лимит</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="zone in zones" :key="zone.id">
            <td scope="row">{{zone.value}}</td>
            <td>{{zone.location}}</td>
            <td>{{zone.busy_stand_sessions_count}} / {{zone.limit}}</td>
            <td>
              <div class="d-flex justify-content-end">
                <router-link
                  class="btn btn-sm ms-3"
                  :to="{ name: 'zones_edit', params: { value: zone.value, id: zone.id } }"
                >
                  <font-awesome-icon icon="pen" class="text-dark"></font-awesome-icon>
                </router-link>
                <a
                  class="btn btn-sm ms-3"
                  href="#"
                  @click.prevent="currentZone = zone; $bvModal.show('bv-modal')"
                >
                  <font-awesome-icon icon="trash" class="text-danger"></font-awesome-icon>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <b-modal id="bv-modal" hide-footer>
        <template v-slot:modal-title>Подтвердите действие</template>
        <div class="d-block text-center">
          <h4 v-if="currentZone">Зона {{currentZone.value}} будет удалена</h4>
        </div>
        <b-button class="mt-3" block variant="danger" @click="removeZone">Удалить</b-button>
        <b-button class="mt-3" block @click="$bvModal.hide('bv-modal')">Отменить</b-button>
      </b-modal>
    </div>
    <div v-else class="loader text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>
<script>

export default {
  name: "zones_index",

  data() {
    return {
      zones: null,
      currentZone: null,
    };
  },

  created() {
    this.getZones();
  },

  methods: {
    async getZones() {
      const response = await this.$apiClient.get("/v1/stand_zones");

      this.zones = response.data.stand_zones;
    },

    async removeZone() {
      let zone = this.currentZone;

      await this.$apiClient.delete(`/v1/stand_zones/${zone.id}`);

      this.zones.splice(this.zones.indexOf(zone), 1);
      this.$bvModal.hide("bv-modal");
      this.currentZone = null;
    },
  },
};
</script>