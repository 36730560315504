import axios from 'axios';
import store from '../store';
import { errorHandling } from './custom_functions';

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

apiClient.interceptors.request.use((config) => {
  config.withCredentials = true;
  config.headers['X-Requested-By'] = 'admin';

  return Promise.resolve(config);
}, (error) => Promise.reject(error));

apiClient.interceptors.response.use((response) => {
  if (response.headers['x-user-rights']) {
    store.state.currentUserRights = JSON.parse(response.headers['x-user-rights']) || null;
  }
  localStorage.userRole = response.headers['x-user-role'];
  return response;
}, (error) => {
  console.error(error.config.skipErrorHandling);

  if ('config' in error && !error.config.skipErrorHandling) {
    errorHandling(error);
  }
  return Promise.reject(error);
});