import { apiClient } from '../../custom_functions/api_client';

export default {
  namespaced: true,

  state: {
    sessions: null,
    totalPages: null,
    currentPage: null,
  },

  actions: {
    async fetchUserSessions({ commit }, payload) {
      const response = await apiClient.get(
        `/v1/users/${payload.userId}/sessions`,
        { params: payload.params }
      );

      commit('setUserSessions', response.data.stands_sessions);
      commit('setUserSessionTotalPages', response.data.meta.total_pages);
      commit('setUserSessionCurrentPage', response.data.meta.current_page)
    },
  },

  mutations: {
    setUserSessions(state, sessions) {
      state.sessions = sessions;
    },

    setUserSessionTotalPages(state, totalPages) {
      state.totalPages = totalPages;
    },

    setUserSessionCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
    }
  },

  getters: {
    getUserSessions(state) {
      return state.sessions;
    },

    getUserSessionTotalPages(state) {
      return state.totalPages;
    },

    getUserSessionCurrentPage(state) {
      return state.currentPage;
    },
  },
}