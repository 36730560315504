<template>
  <div class="container">
    <div class="mb-4">
      <div class="d-flex no-block align-items-center justify-content-between">
        <h2 class="mb-0">Компании</h2>

        <router-link
          class="btn btn-primary"
          to="/companies/new"
        >Добавить Компанию</router-link>
      </div>
    </div>

    <div v-if="companies" class="table-responsive">
      <table class="table table-hover table-responsive-lg">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Название</th>
            <th scope="col">Студентов</th>
            <th
              scope="col"
              style="width: 110px;"
            ></th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(company, index) in companies"
            :key="index"
          >
            <th scope="row" class="align-middle">{{ company.id }}</th>
            <td>
              <router-link
                class="btn btn-link"
                :to="{ name: 'companies_show', params: { id: company.id} }"
              >
                {{ company.name }}
              </router-link>

            </td>
            <td class="align-middle">{{ company.students_count }}</td>
            <td>
              <router-link
                class="btn btn-link"
                :to="{ name: 'companies_edit', params: { id: company.id} }"
              >
                <font-awesome-icon icon="pen"></font-awesome-icon>
              </router-link>
              <a
                class="btn btn-link"
                v-if="company.students_count === 0"
                href="#"
                @click.prevent="currentComapany = company; $bvModal.show('bv-modal')"
              >
                <font-awesome-icon
                  icon="trash"
                  class="text-danger"
                />
              </a>

              <span
                v-else
                class="btn btn-link"
                v-b-tooltip.hover.left
                title="Нельзя удалить. У компании есть студенты."
              >
                <font-awesome-icon
                  icon="trash"
                  class="text-secondary"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <b-modal
        id="bv-modal"
        hide-footer
      >
        <template v-slot:modal-title>Подтвердите действие</template>
        <div class="d-block text-center">
          <h4 v-if="currentComapany">Компания «{{ currentComapany.name }}» будет удалена</h4>
        </div>

        <b-button
          class="mt-3"
          block
          variant="danger"
          @click="deleteCompany"
        >Удалить</b-button>
        <b-button
          class="mt-3"
          block
          @click="$bvModal.hide('bv-modal')"
        >Отменить</b-button>
      </b-modal>
    </div>
    <div v-else class="loader text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/custom_functions/custom_functions.js";

export default {
  name: "companies_index",

  data() {
    return {
      companies: null,
      currentComapany: null,
    };
  },

  created() {
    this.getCompanies();
  },

  methods: {
    formatDate,
    
    async getCompanies() {
      const response = await this.$apiClient.get("/v2/companies");

      this.companies = response.data.companies;
    },

    async deleteCompany() {
      let company = this.currentComapany;

      await this.$apiClient.delete(`/v2/companies/${company.id}`);

      this.$bvToast.toast("Компания удалена", {
        title: "Уведомление",
        variant: "primary",
        autoHideDelay: 3000,
      });

      this.companies.splice(this.companies.indexOf(company), 1);
      this.$bvModal.hide("bv-modal");
      this.currentComapany = null;
    },
  },
};
</script>