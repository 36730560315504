<template>
  <div>
    <form class="input-group mb-4" @submit.prevent="emitChanges">
      <input v-model="query" type="search" class="form-control">
      <button class="btn btn-outline-primary" type="submit">Найти</button>
    </form>
    <div class="d-flex">
      <div class="form-check me-4" v-for="(checkbox, index) in checkboxes" :key="index">
        <input v-model="searchableTypes" class="form-check-input" type="checkbox" :value="checkbox.value" :id="checkbox.value" @change="emitChanges">
        <label class="form-check-label" :for="checkbox.value">
          {{ checkbox.text }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchControl',

  data() {
    return {
      query: '',
      searchableTypes: [],
      checkboxes: [
        { value: 'Product', text: 'курсы' },
        { value: 'Course', text: 'тарифы' },
        { value: 'Unit', text: 'юниты' },
        { value: 'Lesson', text: 'уроки' },
        { value: 'Step', text: 'шаги' },
        { value: 'Post', text: 'дневники курсов' },
        { value: 'Webinar', text: 'вебинары' },
      ],
    }
  },

  props: {
    modelValue: {
      query: '',
      searchableTypes: [],
    },
  },

  created() {
    this.init();
  },

  watch: {
    $route() {
      this.init();
    },
  },

  methods: {
    init() {
      if (this.$route) {
        if (this.$route.query.query) this.query = this.$route.query.query;
        if (this.$route.query.searchable_type) {
          const querySearchableTypes = this.$route.query.searchable_type;
          this.searchableTypes = typeof querySearchableTypes === 'string' ? [querySearchableTypes] : querySearchableTypes;
        }
      }
      this.emitChanges();
    },

    emitChanges() {
      this.$emit('update:modelValue', { query: this.query, searchableTypes: this.searchableTypes })
    }
  },
};
</script>
