<template>
  <div>
  <b-button variant="outline-primary" @click="showModal = true" class="w-100">Изменить урок</b-button>

  <b-modal v-model="showModal" hide-footer title="Редактирование урока">
    <div class="mb-3">
      <label for="lesson-title" class="mb-2">Название урока:</label>
      <input type="text" class="form-control" v-model.lazy="lesson.title" id="lesson-title" />
    </div>
    <div class="mb-3">
      <label for="lesson-description" class="mb-2">Описание урока:</label>
      <input type="text" class="form-control" v-model.lazy="lesson.description" id="lesson-description" />
    </div>
    <div class="mb-3 mb-4">
      <label for="lesson-publication-status" class="mb-2">Статус:</label>
      <CustomSelect
        v-model:model-value.lazy="lesson.publication_status"
        :options="statuses"
        id="lesson-publication-status"
        class="mb-3"
      />
    </div>

    <b-button variant="outline-primary" block @click="saveLesson" class="me-2">Сохранить</b-button>
    <b-button block variant="outline-secondary" @click="showModal = false">Отменить</b-button>
  </b-modal>
  </div>
</template>

<script>
import { makeToast } from '../../custom_functions/custom_functions';
import CustomSelect from '@/components/CustomSelect.vue';

export default {
  name: 'LessonSettings',

  components: {
    CustomSelect,
  },

  data() {
    return {
      showModal: false,
      statuses: [
        { value: 'draft', name: 'Черновик' },
        { value: 'published', name: 'Опубликован' },
        { value: 'hidden', name: 'Приватный' },
      ],
    };
  },

  computed: {
    lesson() {
      return this.$store.getters['lesson/getLesson'];
    },
  },

  methods: {
    async saveLesson() {
      await this.$apiClient.patch( `/v1/lessons/${this.lesson.id}`, { lesson: this.lesson } );
      this.showModal = false;
      makeToast('Изменения в уроке сохранены.', 'success')
    },
  },
};
</script>
