<template>
  <div v-if="currentRecommendedCourses">
    <div class="d-flex border rounded pt-3">
      <draggable v-model:model-value="currentRecommendedCourses" itemKey="id"  class="col w-50">
        <template v-slot:item="{ element }">
          <div v-if="allCourses" class="d-flex flex-row align-items-baseline ms-4">
            <font-awesome-icon icon="grip-vertical" class="text-muted drag-icon me-2"/>
            <div class="col-8">
              <CustomSelect
                v-model:model-value="element.id"
                :options="allCourses"
                :id="`course-${element.id}`"
                class="mb-3 w-100"
              />
            </div>
          </div>
        </template>
      </draggable>
      <div class="col w-50">
        <div class="d-flex flex-column w-75">
          <p>Эти курсы попадут в карточку рекомендации, раздел “Мои курсы”.</p>
          <p>Если хотите видеть тут свой курс, напишите <a href="https://telegram.me/afedorenko06" class="text-primary fw-bold text-nowrap">Саше Федоренко</a>. Она в порядке очереди и приоритетов добавит курс в рекомендации.</p>
        </div>
      </div>
    </div>
    <button @click.prevent="save" class="btn btn-primary my-3 mb-5">Сохранить</button>
  </div>
  <div v-else>
    <b-spinner></b-spinner>
  </div>
</template>
<script>
import CustomSelect from '@/components/CustomSelect.vue';
import { mapActions, mapGetters } from 'vuex';
import { makeToast } from '../custom_functions/custom_functions';

export default {
  name: 'MyCoursesRecommendations',

  components: {
    CustomSelect,
  },

  data() {
    return {
      currentRecommendedCourses: null,
      allCourses: null,
    };
  },

  computed: {
    ...mapGetters('myCoursesRecommendations', ['getRecommendedCourses']),
    ...mapGetters('courses', ['getCoursesOmitPagination']),
  },

  watch: {
    getCoursesOmitPagination: {
      handler(value) {
        if (value) {
          this.allCourses = value.map((course) => ({ name: course.name, value: course.id }));
        }
      },
      deep: true,
    },

    getRecommendedCourses: {
      handler(value) {
        if (value.length) {
          this.currentRecommendedCourses = value.map((course) => ({ id: course.id }));
          while(this.currentRecommendedCourses.length < 3) this.currentRecommendedCourses.push({ id: null });
        }
        else {
          this.currentRecommendedCourses = [{ id: null }, { id: null }, { id: null }];
        }
      },
      deep: true,
    },
  },

  created() {
    this.fetchCoursesOmitPagination();
    this.fetchRecommendedCourses();
  },

  methods: {
    ...mapActions('courses', ['fetchCoursesOmitPagination']),
    ...mapActions('myCoursesRecommendations', [
      'fetchRecommendedCourses',
      'changeRecommendedCourses',
    ]),
    save() {
      this.changeRecommendedCourses(this.currentRecommendedCourses.map((course) => course.id));
      makeToast('Рекомендуемые курсы изменены', 'success');
    },
  },
};
</script>
