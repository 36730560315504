<template>
  <div class="container">
    <h2 class="mb-4">Тестовая страница Errbit</h2>

    <b-alert variant="success" show v-if="isErrbit">Errbit настроен</b-alert>
    <b-alert variant="danger" show v-else>Errbit настроен некорректно, проверьте настройку ключа Errbit</b-alert>

    <button class="btn btn-primary"
            data-toggle="tooltip"
            @click=makeException>Вызвать исключение</button>
  </div>
</template>

<script>
import { errbit } from '@/custom_functions/errbit';

export default {
  name: "errbit",

  data() {
    return {
      isErrbit: false
    }
  },

  created() {
    if (errbit) {
      this.isErrbit = true;
    }
  },

  methods: {
    makeException() {
      throw new Error('Hello from Airbrake!');
    }
  }
};
</script>
