<template>
  <div>
    <label :for="id" v-if="label" class="mb-2">{{ label }}{{ required ? '*' : '' }}</label>
    <v-select v-if="multiple || search" v-model:model-value="currentValue" :reduce="option => option.value"
      :options="options" :id="id" :class="textClass" :disabled="disabled" :multiple="multiple" :placeholder="placeholder"
      :filterable="filterable" @search="searchDelay" label="name">
      <template #open-indicator>
        <font-awesome-icon icon="chevron-down" size="xs" class="select-open-indicator" />
      </template>
    </v-select>
    <select v-else class="form-select" v-model.lazy="currentValue" :id="id" :class="textClass" :disabled="disabled">
      <option :value="undefined" disabled class="d-none" v-if="placeholder && currentValue === undefined">{{ placeholder
      }}</option>
      <option :value="null" disabled class="d-none" v-if="placeholder && currentValue === null">{{ placeholder }}</option>
      <option v-for="(option, index) in options" :value="option.value" :key="index">{{ option.name }}</option>
    </select>
    <span class="invalid-feedback" v-for="(error, index) in errors" :key="index">{{ error }}</span>
    <small class="text-muted" v-if="tip">{{ tip }}</small>
    <b-tooltip :target="id" v-if="tooltip">{{ tooltip }}</b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',

  props: {
    modelValue: { required: true },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    id: { type: String, required: true },
    placeholder: { type: String, default: '' },
    options: {
      type: Array,
      required: true,
      validator: (value) => value.every((option) => option.hasOwnProperty('value') && option.hasOwnProperty('name')),
    },
    tip: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    errors: { type: Array, default: () => [] },
    tooltip: { type: String, default: '' },
    search: { type: [Function, Boolean], default: false },
    filterable: { type: Boolean, default: true },
  },

  data() {
    return {
      currentValue: null,
      searchTimeout: null,
    };
  },

  computed: {
    textClass() {
      return {
        'text-muted': (this.currentValue === undefined || this.currentValue === null || (Array.isArray(this.currentValue) && this.currentValue.length === 0)),
        'is-invalid': this.errors.length,
        'taggable': this.multiple,
      };
    },
  },

  created() {
    this.currentValue = this.modelValue;
  },

  watch: {
    modelValue(value) {
      this.currentValue = value;
    },

    currentValue(value) {
      this.$emit('update:modelValue', value);
    },
  },

  methods: {
    searchDelay(query) {
      if (typeof this.search === 'function') {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.search(query), 500);
      };
    },
  },
}
</script>
<style scoped>
.select-open-indicator {
  margin: 0 6px;
}

.is-invalid .select-open-indicator {
  margin-left: 32px;
}
</style>
<style>
.vs__dropdown-toggle {
  min-height: 38px;
}

.taggable .vs__selected {
  background-color: #ced4da !important;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  padding: 0 8px !important;
}

.taggable .vs__deselect {
  fill: #495057 !important;
  margin-left: 8px !important;
}

.is-invalid .vs__dropdown-toggle {
  border-color: #dc3545;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 28px center;
  background-size: 18px 18px;
}

.text-muted input::placeholder {
  color: #6c757d !important;
}
</style>
