<template>
  <div class="container">
    <div class="d-flex">
      <h1>Заказ #{{ order.id }}</h1>

      <div class="flex-grow-1"></div>

      <div class="ms-2">
        <button class="btn btn-danger" @click="discard()">
          Удалить
        </button>
      </div>

      <div class="ms-2" v-for="event in allowedEvents">
        <button class="btn btn-primary" @click="transition(event)">
          {{ localizeEvent(event) }}
        </button>
      </div>
    </div>

    <div class="card card-body my-4">
      <p>
        <strong>Состояние:</strong>&nbsp;
        {{ localizeState(order.state) }}
      </p>
      <p v-if="order.total_amount">
        <strong>Сумма:</strong>&nbsp;
        {{ formatPrice(order.total_amount) }}
      </p>
      <p v-if="order.amount_without_discount">
        <strong>Сумма без скидки:</strong>&nbsp;
        {{ formatPrice(order.amount_without_discount) }}
      </p>
      <p v-if="order.campaign">
        <strong>Промокод:</strong>&nbsp;
        <router-link :to="`/promo_codes/${order.campaign.id}/edit`">{{ order.campaign.name }}</router-link>
      </p>
      <p>
        <strong>Счёт в CRM:</strong>&nbsp;
        <a v-if="order.invoice_url" :href="order.invoice_url">{{ order.invoice_url }}</a>
        <a v-else :href="'javascript:void(0)'" @click="createInvoice()">{{ "Создать" }}</a>
      </p>
      <p v-if="order.total_amount > 0">
        <strong>Платеж в CRM:</strong>&nbsp;
        <a v-if="order.payment_url" :href="order.payment_url">{{ order.payment_url }}</a>
        <a v-else href="javascript:void(0)" @click="createPayment()">{{ "Создать" }}</a>
      </p>
      <p v-if="order.tinkoff_payload && order.tinkoff_payload.link">
        <strong>Tinkoff:</strong>&nbsp;
        <a :href="order.tinkoff_payload.link">{{ order.tinkoff_payload.link }}</a> (<a href="javascript:void(0)" @click="recreateTinkoff()">Обновить ссылку</a>)
      </p>
      <p>
        <strong>UTM:</strong>&nbsp;
        {{ order.utm_data }}
      </p>
      <p>
        <strong>Студент:</strong>&nbsp;
        <router-link :to="{ name: 'users_edit', params: { id: order.user.id} }">{{ order.user.full_name || order.user.email }}</router-link> ({{ order.user.login }})
      </p>
      <p v-if="order.comment">
        <strong>Комментарий:</strong> {{ order.comment }}
      </p>
    </div>

    <div class="card my-4">
      <div class="card-header">
        Позиции
      </div>

      <div class="card-body table-responsive">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Название</th>
            <th>Цена</th>
            <th>Цена без скидки</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="item in order.items" :key="item.id">
            <td>
              <router-link :to="{ name: 'courses_edit', params: { slug: item.course_id} }">{{item.name}}</router-link>
            </td>
            <td>{{ formatPrice(item.price) }}</td>
            <td>{{ formatPrice(item.source_price) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card my-4" v-if="order.transactions.length > 0">
      <div class="card-header">
        Транзакции
      </div>

      <div class="card-body">
        <pre>{{ order.transactions }}</pre>
      </div>
    </div>

    <div class="card my-4">
      <div class="card-header">
        История
        <span class="badge text-bg-primary">{{ order.versions.length }}</span>
      </div>

      <div class="card-body">
        <div class="d-flex my-4" v-for="version in order.versions" :key="version.id">
          <div class="me-4">
            <font-awesome-icon :icon="version.event === 'create' ? 'plus-circle' : 'pencil-alt'" size="md"/>
          </div>

          <div class="table-responsive">
            <small class="text-muted">
              {{ formatDateTime(version.created_at) }}
              <template v-if="version.user">
                <router-link :to="{ name: 'users_edit', params: { id: version.user.id } }">{{ version.user.full_name || version.user.email }}</router-link>
              </template>
            </small>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Поле</th>
                <th>Старое значение</th>
                <th>Новое значение</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(change, field) in version.changeset">
                <td>{{ field }}</td>
                <td>{{ change[0] }}</td>
                <td>{{ change[1] }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTime, formatPrice } from '@/custom_functions/custom_functions'

export default {
  name: "OrdersShow",
  data() {
    return {
      order: null
    }
  },

  created() {
    this.fetchOrder()
  },

  computed: {
    allowedEvents() {
      return ['complete', 'reset'].filter(value => this.order.allowed_events.includes(value))
    }
  },

  methods: {
    formatPrice,
    formatDateTime,
    
    async fetchOrder() {
      const response = await this.$apiClient.get(`v2/orders/${this.$route.params.id}`)
      this.order = response.data.order
    },

    async transition(event) {
      const response = await this.$apiClient.post(`v2/orders/${this.order.id}/transition/${event}`)
      this.order = response.data.order
    },

    async discard() {
      await this.$apiClient.delete(`v2/orders/${this.order.id}`);
      await this.$router.replace({name: 'orders_index'});
    },

    localizeEvent(name) {
      const mapping = { complete: 'Завершить', reset: 'Сбросить' }
      return mapping[name]
    },

    localizeState(name) {
      const mapping = { idle: 'Новый', processing: 'В обработке', completed: 'Завершён', waiting_for_payment: 'Ожидает оплаты' }
      return mapping[name]
    },
    async createInvoice() {
      const response = await this.$apiClient.post(`v2/orders/${this.order.id}/create_invoice`)
      this.order.invoice_url = response.data.order.invoice_url
    },
    async createPayment() {
      const response = await this.$apiClient.post(`v2/orders/${this.order.id}/create_payment`)
      this.order.payment_url = response.data.order.payment_url
    },

    async recreateTinkoff() {
      await this.$apiClient.patch(`v2/tinkoff/installment?order_id=${this.order.id}&acquiring=tinkoff`)
      window.reload();
    }
  }
}
</script>
