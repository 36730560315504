<template>
  <form v-if="users">
    <div class="mb-3">
      <label
        for="new-reviewer"
        class="mb-2"
      >
        Добавить ревьюера:
      </label>
      <v-select
        :options="users"
        label="email"
        v-model="newReviewerUser"
        @search="searchUsers"
      />
      <b-modal
        title="Добавление ревьюера"
        v-model="visibleModal"
        v-if="newReviewerUser"
        hide-footer
      >
        <p>
          Выберите курс, на который пользователь
          {{ newReviewerUser.email }} будет назначен ревьюером.
        </p>
        <v-select
          :options="courses"
          label="name"
          v-model:model-value="newReviewerCourse"
          class="mb-3"
        />
        <div class="row">
          <div class="col-6">
            <button
              class="btn btn-primary w-100"
              @click="createReviewer"
              :disabled="!newReviewerCourse"
            >
              Добавить ревьюера
            </button>
          </div>
          <div class="col-6">
            <button
              class="btn btn-secondary w-100"
              @click="newReviewerUser = null"
            >
              Отмена
            </button>
          </div>
        </div>
      </b-modal>
    </div>
  </form>
</template>

<script>
import { makeToast } from '@/custom_functions/custom_functions';
import { debounce } from 'lodash-es';

export default {
  name: 'ReviewersCreating',

  data() {
    return {
      newReviewerUser: null,
      newReviewerCourse: null,
      visibleModal: false,
    };
  },

  computed: {
    reviewers() {
      return this.$store.getters['reviewers/getReviewers'];
    },

    users() {
      return this.$store.getters['users/getUsers'];
    },

    courses() {
      return this.$store.getters['courses/getCoursesOmitPagination'];
    },
  },

  watch: {
    newReviewerUser(value) {
      this.visibleModal = !!value;
    },

    visibleModal(value) {
      if (!value) {
        this.newReviewerUser = null;
        this.newReviewerCourse = null;
      }
    },
  },

  methods: {
    searchUsers: debounce(function (search) {
      this.$store.dispatch('users/fetchUsersByEmail', search);
    }, 1000),

    async createReviewer() {
      const response = await this.$apiClient.post('/v2/reviewers', {
        reviewer: {
          user_id: this.newReviewerUser.id,
          course_id: this.newReviewerCourse.id,
        },
      });
      this.$store.commit('reviewers/updateReviewers', [
        ...this.reviewers,
        response.data.reviewer,
      ]);
      this.visibleModal = false;
      makeToast('Ревьюер добавлен', 'success');
    },
  },
};
</script>
